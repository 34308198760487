import { protectedApi } from "./api";

export const fetchExistingData = async (endpoint) => {
  try {
    const response = await protectedApi.get(endpoint);
    console.log(`[GET] ${endpoint} response:`, response.data);

    if (response.data?.results?.length > 0) {
      const activeItems = response.data.results.filter(
        (item) => !item.is_deleted
      );
      return activeItems;
    }
    return [];
  } catch (error) {
    console.error(`[GET] Error fetching data from ${endpoint}:`, error);
    throw error;
  }
};

export const handleFormSubmit = async ({
  endpoint,
  items,
  deletedItems,
  transformItem,
  applicationId,
}) => {
  try {
    if (!applicationId) {
      throw new Error("No active application found");
    }

    // Filter out active items (not deleted)
    const activeItems = items.filter((item) => !deletedItems.has(item.id));

    // Prepare items for submission
    const itemsToSubmit = activeItems.map((item) => transformItem(item));

    // Separate items into updates and creates
    const itemsToUpdate = itemsToSubmit.filter((p) => p.id);
    const itemsToCreate = itemsToSubmit.filter((p) => !p.id);

    const results = {
      created: [],
      updated: [],
      deleted: [],
    };

    // Handle updates
    if (itemsToUpdate.length > 0) {
      const updateResponse = await protectedApi.patch(endpoint, itemsToUpdate, {
        headers: {
          "Content-Type": "application/json",
          "X-Application-ID": applicationId,
        },
      });
      results.updated = updateResponse.data;
    }

    // Handle creates
    if (itemsToCreate.length > 0) {
      const createResponse = await protectedApi.post(endpoint, itemsToCreate, {
        headers: {
          "Content-Type": "application/json",
          "X-Application-ID": applicationId,
          "X-CSRFToken": document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
        },
      });
      results.created = createResponse.data;
    }

    // Handle deletions
    if (deletedItems.size > 0) {
      const deletePromises = Array.from(deletedItems).map((itemId) =>
        protectedApi.delete(`${endpoint}${itemId}/`, {
          headers: {
            "Content-Type": "application/json",
            "X-Application-ID": applicationId,
          },
        })
      );
      await Promise.all(deletePromises);
      results.deleted = Array.from(deletedItems);
    }

    // Call recordFormProgress after successful save
    await recordFormProgress(endpoint, applicationId);

    return results;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};
export const getApplicationId = async () => {
  try {
    if (
      localStorage.getItem("applicationId") !== "null" ||
      localStorage.getItem("applicationId") !== "undefined"
    ) {
      return localStorage.getItem("applicationId");
    }
    const response = await protectedApi.get("/api/application/");
    if (response.data && response.data.id) {
      localStorage.setItem("applicationId", response.data.id);
      return response.data.id;
    }
    return null;
  } catch (error) {
    console.error("[GET] Error fetching application ID:", error);
    return null;
  }
};

export const productMap = {
  "Recommendation Letter": "32f7b8ae-7af9-49e2-b605-431917388101",
  "RFE Notice Analysis": "361fdb59-26bd-46e3-b850-855d9c8b6450",
  "RFE Response": "82899e6b-1952-4d01-a406-3a554d5c9a9c",
  "Proposed Endeavors": "ace2921a-d462-4862-8db6-dddeb49e24e6",
  "Petition Letter": "b6bfaf9e-e88b-4ff3-a618-497691d5c0e7",
};

export const checkUsageLimits = async (productType, showAlert) => {
  try {
    const usageResponse = await protectedApi.get("/api/payments/usage/");

    if (usageResponse.status === 200) {
      if (!usageResponse.data || usageResponse.data.length === 0) {
        const confirmPurchase = await showAlert(
          `You have no ${productType} credits remaining. Would you like to purchase more credits?`,
          "Purchase Credits",
          "Cancel"
        );
        if (confirmPurchase) {
          return {
            success: false,
            action: "redirect",
            message: "Redirecting to plans page",
          };
        }
        return {
          success: false,
          message: `No ${productType} credits available. Please purchase credits to continue.`,
        };
      }

      const productId = productMap[productType];
      const usageStatus = usageResponse.data.find(
        (status) => status.product === productId
      );

      const remaining_usage = usageStatus ? usageStatus.remaining_usage : 0;
      const totalBalance = usageStatus ? usageStatus.total_purchased : 0;

      if (remaining_usage === 0) {
        const confirmPurchase = await showAlert(
          `You have no ${productType} credits remaining. Would you like to purchase more credits?`,
          "Purchase Credits",
          "Cancel"
        );
        if (confirmPurchase) {
          return {
            success: false,
            action: "redirect",
            message: "Redirecting to plans page",
          };
        }
        return {
          success: false,
          message: `No ${productType} credits remaining. Please purchase more credits to continue.`,
        };
      }

      const confirmGeneration = await showAlert(
        `You have ${totalBalance} times to generate ${productType} and ${remaining_usage} times remaining. Would you like to proceed?`,
        "Proceed",
        "Cancel"
      );

      if (!confirmGeneration) {
        return {
          success: false,
          message: "Generation cancelled by user",
        };
      }

      return {
        success: true,
        remaining_usage,
        totalBalance,
      };
    }

    return {
      success: false,
      message: "Failed to fetch usage data",
    };
  } catch (error) {
    console.error("Error checking usage limits:", error);
    return {
      success: false,
      message: "Error checking usage limits. Please try again later.",
    };
  }
};

export const recordFormProgress = async (endpoint, applicationId) => {
  try {
    // 假设你有一个 API 可以更新进度条状态
    const progressEndpoint = `/api/progress/${applicationId}/`;
    const progressData = {
      section: "Education",
      status: "completed", // 或者其他你定义的状态
    };

    await protectedApi.patch(progressEndpoint, progressData, {
      headers: {
        "Content-Type": "application/json",
        "X-Application-ID": applicationId,
      },
    });

    console.log("Progress for Education section recorded successfully.");
  } catch (error) {
    console.error("Error recording form progress:", error);
  }
};
