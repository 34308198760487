import React, { useState, useEffect, useContext } from "react";
import styles from "./RFE.module.css";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import config from "../../config.js";
import Sidebar from "../common/Sidebar.js";
import Button from "../common/Button.js";
import axios from "axios";
import Stepper from "../common/ProgressBar.js";
import { protectedApi, handleAuthError } from "../../services/api.js";
import Header2 from "../common/Header2.js";
import { usePreviousNavigation } from "../../services/handlePrevious";
import { useAlert } from "../common/AlertContext.js";
import { useLoading } from "../common/LoadingContext";
import { ProgressBarContext } from "./ProgressBar.js";
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};

function RFEInfoPage() {
  const { handleComplete } = useContext(ProgressBarContext);
  const navigate = useNavigate();

  const [rfe, setRFE] = useState([
    {
      file: null,
      description: "",
      sent_date: "",
      deadline: "",
    },
  ]);

  const [errors, setErrors] = useState([]);
  const [fileName, setFileName] = useState(""); // Add this state
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [openRFE, setOpenRFE] = useState([true]);
  const [deletedRFEs, setDeletedRFEs] = useState(new Set());
  const { showAlert } = useAlert();
  const { showLoading, hideLoading } = useLoading();
  useEffect(() => {
    const fetchExistingRFEs = async () => {
      try {
        showLoading("Fetching RFEs...");
        const applicationId = localStorage.getItem("applicationId");
        if (!applicationId) {
          // showAlert(
          //   "No active application found. Please create a new application."
          // );
          // navigate("/personal-info");
          return;
        }

        const response = await protectedApi.get("/api/rfenotice/", {
          headers: {
            "X-Application-ID": applicationId,
          },
        });

        if (response.data.results && response.data.results.length > 0) {
          setRFE(response.data.results);
          setOpenRFE(new Array(response.data.results.length).fill(true));
        }
      } catch (error) {
        if (handleAuthError(error, navigate)) return;
        console.error("Error fetching RFEs:", error);
        showAlert("Error fetching RFEs");
      } finally {
        hideLoading();
      }
    };

    fetchExistingRFEs();
  }, [navigate]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setRFE((prevRFE) => {
      const updatedRFE = [...prevRFE];
      updatedRFE[index][name] = value;
      return updatedRFE;
    });
  };
  const handleCancel = () => {
    navigate("/");
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = rfe.map((rfeItem, index) => {
      const rfeErrors = {};

      // Required fields validation
      const requiredFields = [
        { key: "description", label: "Description" },
        { key: "sent_date", label: "Sent Date" },
        { key: "deadline", label: "Deadline" },
      ];

      requiredFields.forEach(({ key, label }) => {
        if (!rfeItem[key] || String(rfeItem[key]).trim() === "") {
          rfeErrors[key] = `${label} is required`;
          isValid = false;
        }
      });

      // File validation for new RFEs
      if (!rfeItem.id && !files[index]) {
        rfeErrors.supporting_doc = "Supporting document is required";
        isValid = false;
      }

      return rfeErrors;
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();
    console.log("Starting form submission");

    try {
      const applicationId = localStorage.getItem("applicationId");
      if (!applicationId) {
        showAlert("No application found, please create one in Personal Info");
        return;
      }

      // Validate form before submission
      if (!validateForm()) {
        return;
      }

      // Filter out active RFEs (not deleted)
      const activeRFEs = rfe.filter((rfeItem) => !deletedRFEs.has(rfeItem.id));
      console.log("Active RFEs:", activeRFEs);

      for (let i = 0; i < activeRFEs.length; i++) {
        const rfeItem = activeRFEs[i];
        const formData = new FormData();

        // Get the file from the files array
        const currentFile = files[i];
        console.log(`Processing RFE ${i} with file:`, currentFile);

        // Check if file is required and exists
        if (!rfeItem.id && !currentFile) {
          setErrors((prev) => {
            const newErrors = [...prev];
            if (!newErrors[i]) newErrors[i] = {};
            newErrors[i].supporting_doc = "Supporting document is required";
            return newErrors;
          });
          throw new Error("Supporting document is required");
        }

        // Append file if it exists
        if (currentFile instanceof File) {
          formData.append("file", currentFile);
          console.log("File appended to FormData:", currentFile.name);
        }

        // Append other form data
        formData.append("description", rfeItem.description || "");
        formData.append("sent_date", rfeItem.sent_date || "");
        formData.append("deadline", rfeItem.deadline || "");
        formData.append("application", applicationId);

        // Log FormData entries for debugging
        for (let pair of formData.entries()) {
          console.log(
            "FormData entry:",
            pair[0],
            ":",
            pair[1] instanceof File ? pair[1].name : pair[1]
          );
        }

        const config = {
          headers: {
            "X-Application-ID": applicationId,
          },
        };

        try {
          if (rfeItem.id) {
            await protectedApi.patch(
              `/api/rfenotice/${rfeItem.id}/`,
              formData,
              config
            );
          } else {
            await protectedApi.post("/api/rfenotice/", formData, config);
          }
        } catch (error) {
          console.error("API Error:", error.response?.data || error);
          if (handleAuthError(error, navigate)) return;
          throw error;
        }
      }

      if (action === "continue") {
        handleComplete("rfe");
        navigate("/");
      } else {
        handleComplete("rfe");
        showAlert("RFE saved successfully!");
      }
    } catch (error) {
      console.error("Form submission error:", error);
      if (error.response?.data) {
        const errorMessage = Array.isArray(error.response.data)
          ? error.response.data.map((err) => err.error).join("\n")
          : error.response.data.error || "Unknown error";
        showAlert(`Error: ${errorMessage}`);
        if (handleAuthError(error, navigate)) return;
      } else {
        showAlert(error.message || "Error saving RFE");
      }
    }
  };

  const handleSave = (e) => handleFormSubmit(e, "save");
  const handleContinue = (e) => handleFormSubmit(e, "continue");

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    console.log("File selected:", file);

    if (file) {
      // Update files state with proper array initialization
      setFiles((prev) => {
        const newFiles = Array(rfe.length).fill(null);
        newFiles[index] = file;
        console.log("Updated files array:", newFiles);
        return newFiles;
      });

      // Update fileNames state
      setFileNames((prev) => {
        const newNames = Array(rfe.length).fill("");
        newNames[index] = file.name;
        return newNames;
      });

      // Update RFE state with file reference
      setRFE((prev) => {
        const newRFE = [...prev];
        if (!newRFE[index]) newRFE[index] = {};
        newRFE[index] = {
          ...newRFE[index],
          file: file,
        };
        console.log("Updated RFE state:", newRFE);
        return newRFE;
      });

      // Clear any existing errors
      setErrors((prev) => {
        const newErrors = [...prev];
        if (newErrors[index]) {
          delete newErrors[index].supporting_doc;
        }
        return newErrors;
      });
    }
  };

  const removeFile = (index) => {
    setFiles((prev) => {
      const newFiles = [...prev];
      newFiles[index] = null;
      return newFiles;
    });

    setFileNames((prev) => {
      const newNames = [...prev];
      newNames[index] = "";
      return newNames;
    });

    setRFE((prev) => {
      const newRFE = [...prev];
      newRFE[index] = {
        ...newRFE[index],
        file: null,
      };
      return newRFE;
    });
  };

  const addNewRFE = () => {
    setRFE((prevRFE) => [
      ...prevRFE,
      {
        file: null,
        description: "",
        sent_date: "",
        deadline: "",
      },
    ]);
    setOpenRFE((prevRFE) => [...prevRFE, true]);
    setErrors((prevErrors) => [...prevErrors, {}]);
  };

  const toggleRFE = (index) => {
    setOpenRFE((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleDeleteRFE = async (index) => {
    try {
      const rfeToDelete = rfe[index];

      // Don't allow deletion if it's the last RFE
      if (rfe.length <= 1) {
        showAlert("You must have at least one RFE.");
        return;
      }

      // Make DELETE request if the RFE has an ID
      if (rfeToDelete.id) {
        await protectedApi.delete(`/api/rfenotice/${rfeToDelete.id}/`);
        setDeletedRFEs((prev) => new Set([...prev, rfeToDelete.id]));
      }

      // Update local state
      setRFE((prevRFE) => prevRFE.filter((_, idx) => idx !== index));
      setOpenRFE((prevState) => prevState.filter((_, idx) => idx !== index));
      setErrors((prevErrors) => prevErrors.filter((_, idx) => idx !== index));
    } catch (error) {
      console.error("Error deleting RFE:", error);
      if (handleAuthError(error, navigate)) return;
      showAlert("Failed to delete RFE. Please try again.");
    }
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.createProfile}>
          <div className={styles.wrapper}>
            <h1 className={styles.createYourProfile}>Create Your Profile</h1>
            <form onSubmit={handleFormSubmit} encType="multipart/form-data">
              <div className={styles.formContainer}>
                {rfe.map((rfe, index) => (
                  <div key={index} className={styles.formSection}>
                    <div
                      className={styles.rfeHeader}
                      onClick={() => toggleRFE(index)}
                    >
                      <h2
                        className={styles.rfeTitle}
                      >{`Request For Evidence`}</h2>
                      <div className={styles.headerActions}>
                        {index > 0 && (
                          <svg
                            className={styles.deleteIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteRFE(index);
                            }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                          >
                            <path
                              d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <svg
                          className={`${styles.dropdownIcon} ${
                            openRFE[index] ? styles.open : ""
                          }`}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M6 9l6 6 6-6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    <div
                      className={`${styles.rfeContent} ${
                        openRFE[index] ? styles.open : styles.closed
                      }`}
                    >
                      {index === 0 && (
                        <div className={styles.sectionHeader}>
                          <Stepper
                            currentStep={2}
                            totalSteps={7}
                            progressWidth={28}
                          />
                        </div>
                      )}

                      {/* Original Input Fields */}
                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <label className={styles.inputLabel}>
                            Received Date
                          </label>
                          <input
                            type="date"
                            name="sent_date"
                            value={rfe.sent_date}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.sent_date ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.sent_date && (
                            <span className={styles.errorMessage}>
                              {errors[index].sent_date}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <label className={styles.inputLabel}>Deadline</label>
                          <input
                            type="date"
                            name="deadline"
                            placeholder="Deadline"
                            value={rfe.deadline}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.deadline ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.deadline && (
                            <span className={styles.errorMessage}>
                              {errors[index].deadline}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.inputWrapper}>
                        <textarea
                          name="description"
                          placeholder="Description"
                          value={rfe.description}
                          onChange={(e) => handleChange(e, index)}
                          className={`${styles.textArea} ${
                            errors[index]?.description ? styles.errorInput : ""
                          }`}
                          required
                        />
                        {errors[index]?.description && (
                          <span className={styles.errorMessage}>
                            {errors[index].description}
                          </span>
                        )}
                      </div>

                      <div className={styles.fileUploadContainer}>
                        <div className={styles.inputWrapper}>
                          <div className={styles.fileInputGroup}>
                            <input
                              type="file"
                              accept=".pdf"
                              onChange={(e) => handleFileChange(e, index)}
                              className={`${styles.fileInput} ${
                                errors[index]?.supporting_doc
                                  ? styles.errorInput
                                  : ""
                              }`}
                              id={`fileUpload-${index}`}
                            />
                            <label
                              htmlFor={`fileUpload-${index}`}
                              className={`${styles.fileInputLabel} ${
                                errors[index]?.supporting_doc
                                  ? styles.errorLabel
                                  : ""
                              }`}
                            >
                              Upload Supporting Documents (PDF)
                            </label>
                          </div>

                          {errors[index]?.supporting_doc && (
                            <span className={styles.errorMessage}>
                              {errors[index].supporting_doc}
                            </span>
                          )}

                          <div className={styles.uploadedFiles}>
                            {fileNames[index] && (
                              <div className={styles.fileItem}>
                                <span>{fileNames[index]}</span>
                                <button
                                  type="button"
                                  onClick={() => removeFile(index)}
                                  className={styles.removeFile}
                                  aria-label="Remove file"
                                >
                                  ×
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Form Actions - Outside the projects loop */}
                <div className={styles.formActions}>
                  <div className={styles.navButtons}>
                    <Button
                      variant="secondary"
                      onClick={usePreviousNavigation()}
                    >
                      Previous
                    </Button>
                    <Button variant="secondary" onClick={handleSave}>
                      Save
                    </Button>
                    <Button variant="primary" onClick={handleContinue}>
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RFEInfoPage;
