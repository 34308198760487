import React, { createContext, useState } from 'react';

export const ProgressBarContext = createContext();

export const ProgressBarProvider = ({ children }) => {
  const statusBoxes = [
    {
      title: "Step 1: Create Profile",
      items: [
        {
          name: "Personal Info",
          time: 5,
          time_str: "~5mins",
          link: "/personal-info",
          id: "personal-info",
        },
        {
          name: "Education",
          time: 30,
          time_str: "~30mins",
          isDropdown: true,
          section: "education",
          subItems: [
            {
              name: "Education History",
              link: "/education-info",
              id: "education-history",
              time: 15,
              time_str: "~15mins",
            },
            {
              name: "Education Project",
              link: "/scientific-project",
              id: "education-project",
              time: 15,
              time_str: "~15mins",
            },
          ],
        },
        {
          name: "Employment",
          time: 30,
          time_str: "~30mins",
          isDropdown: true,
          section: "employment",
          subItems: [
            {
              name: "Employment History",
              link: "/employment-info",
              id: "employment-history",
              time: 15,
              time_str: "~15mins",
            },
            {
              name: "Employment Project",
              link: "/project-info",
              id: "employment-project",
              time: 15,
              time_str: "~15mins",
            },
          ],
        },
        {
          name: "Evidence",
          time: 15,
          time_str: "~15mins",
          link: "/evidence",
          id: "evidence",
        },
        {
          name: "Future Plan",
          time: 30,
          time_str: "~30mins",
          link: "/future-plans",
          id: "future-plans",
        },
        {
          name: "Optional: Request for Evidence (RFE)",
          link: "/rfe",
          id: "rfe",
          time: 5,
          time_str: "~5mins",
        },
      ],
    },
    {
      title: "Step 2: Select Proposed Endeavors",
      items: [
        {
          name: "Generate Proposed Endeavors",
          link: "/proposed-endeavor",
          id: "proposed-endeavor",
          time: 25,
          time_str: "~25mins",
        },
      ],
    },
    {
      title: "Step 3: Create Recommendation Letters",
      items: [
        {
          name: "Add A Recommender",
          link: "/recommender",
          id: "recommender",
          time: 25,
          time_str: "~25mins",
        },
      ],
    },
    {
      title: "Step 4: Create Petition Letter",
      items: [
        {
          name: "Generate Petition Letter",
          link: "/petition-letter",
          id: "petition-letter",
          time: 10,
          time_str: "~10mins",
        },
      ],
    },
    {
      title: "RFE stage",
      items: [
        {
          name: "RFE Analysis",
          link: "/rfe-analysis",
          id: "rfe-analysis",
          time: 10,
          time_str: "~10mins",
        },
        {
          name: "Generate RFE",
          link: "/rfe-response",
          id: "rfe-response",
          time: 10,
          time_str: "~10mins",
        },
      ],
    },
  ];

  const initialCompletedItems = statusBoxes.reduce((acc, box) => {
    box.items.forEach((item) => {
      if (item.isDropdown) {
        item.subItems.forEach((subItem) => {
          acc[subItem.id] = false;
        });
      } else {
        acc[item.id] = false;
      }
    });
    return acc;
  }, {});
  
  const [completedItems, setCompletedItems] = useState(initialCompletedItems);
  // const { user } = useAuth();

  const handleComplete = (itemId) => {
    setCompletedItems((prevCompletedItems) => ({
      ...prevCompletedItems,
      [itemId]: true, // 将指定的 itemId 设置为 true
    }));
  };

  return (
    <ProgressBarContext.Provider value={{ completedItems, statusBoxes, handleComplete }}>
      {children}
    </ProgressBarContext.Provider>
  );
};