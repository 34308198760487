import React, { useState, useEffect, useContext } from "react";
import styles from "./Evidence.module.css";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { usePreviousNavigation } from "../../services/handlePrevious";
import config from "../../config.js";
import Sidebar from "../common/Sidebar.js";
import Button from "../common/Button.js";
import axios from "axios";
import Stepper from "../common/ProgressBar.js";
import { protectedApi, handleAuthError } from "../../services/api.js";
import {
  EVIDENCE_CATEGORIES,
  MAIN_CATEGORIES,
  STATUS_OPTIONS,
  IMPACT_LEVEL_OPTIONS,
} from "../../constants/dropdownConsts.js";
import CustomDropdown from "../common/Dropdown.js";
import Header2 from "../common/Header2.js";
import { useAlert } from "../common/AlertContext.js";
import { useLoading } from "../common/LoadingContext";
import { ProgressBarContext } from "./ProgressBar.js";

function EvidenceInfoPage() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const { handleComplete } = useContext(ProgressBarContext);
  const [evidence, setEvidence] = useState([
    {
      category: "",
      sub_category: "",
      received_date: "",
      expired_date: "",
      status: "",
      name: "",
      issuing_organization: "",
      brief_introduction: "",
      domain: "",
      level: "",
      supporting_doc: null,
      fileName: "",
    },
  ]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [errors, setErrors] = useState([]);
  const [fileName, setFileName] = useState(""); // Add this state
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [openEvidence, setOpenEvidence] = useState([true]);
  const [selectedMainCategory, setSelectedMainCategory] = useState(
    evidence.map(() => "")
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    evidence.map(() => "")
  );

  const [deletedEvidence, setDeletedEvidence] = useState(new Set());

  const handleMainCategoryChange = (e, index) => {
    const value = e.target.value;
    console.log("Category changed:", value); // Debug log

    setEvidence((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        category: value,
        sub_category: "", // Reset sub-category when main category changes
      };
      console.log("Updated evidence after category change:", updated); // Debug log
      return updated;
    });

    // Update the selected main category state
    setSelectedMainCategory((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };

  const handleSubCategoryChange = (e, index) => {
    const value = e.target.value;

    setEvidence((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        sub_category: value, // Set the sub_category directly
      };
      return updated;
    });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setEvidence((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        [name]: value,
      };
      return updated;
    });

    // Clear error for the changed field
    setErrors((prev) => {
      if (!prev[index]) return prev;
      const newErrors = [...prev];
      newErrors[index] = {
        ...newErrors[index],
        [name]: undefined, // Clear error for this field only
      };
      return newErrors;
    });
  };

  const handleCancel = () => {
    navigate("/");
  };

  const validateForm = () => {
    const newErrors = evidence.map((evidenceItem) => {
      const evidenceErrors = {};

      // Validate category first
      if (!evidenceItem.category || evidenceItem.category.trim() === "") {
        evidenceErrors.category = "Category is required";
      }

      // Only validate sub-category if category is selected
      if (
        evidenceItem.category &&
        (!evidenceItem.sub_category || evidenceItem.sub_category.trim() === "")
      ) {
        evidenceErrors.sub_category = "Sub Category is required";
      }

      // Validate other required fields
      const otherRequiredFields = [
        { key: "received_date", label: "Received Date" },
        { key: "status", label: "Status" },
        { key: "name", label: "Name" },
        { key: "issuing_organization", label: "Issuing Organization" },
        { key: "brief_introduction", label: "Brief Introduction" },
        { key: "domain", label: "Domain" },
        { key: "level", label: "Level" },
      ];

      otherRequiredFields.forEach(({ key, label }) => {
        if (!evidenceItem[key] || String(evidenceItem[key]).trim() === "") {
          evidenceErrors[key] = `${label} is required`;
        }
      });

      // Date validation
      if (evidenceItem.received_date && evidenceItem.expired_date) {
        const start = new Date(evidenceItem.received_date);
        const end = new Date(evidenceItem.expired_date);
        if (end < start) {
          evidenceErrors.expired_date =
            "Expired date must be after received date";
        }
      }

      return evidenceErrors;
    });

    setErrors(newErrors);
    return newErrors.every((errors) => Object.keys(errors).length === 0);
  };

  // Add a debug function to check form data before submission
  const logFormData = () => {
    console.log("Current Evidence State:", evidence);
    console.log("Current Errors:", errors);
  };

  // Update the handleFormSubmit to include debugging
  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();

    if (!validateForm()) {
      // Collect and deduplicate error messages only for display
      const uniqueMessages = Array.from(
        new Set(errors.flatMap((errorObj) => Object.values(errorObj)))
      );

      showAlert(
        "Please fix the following errors:\n" + uniqueMessages.join("\n")
      );
      return;
    }

    try {
      const applicationId = localStorage.getItem("applicationId");
      if (!applicationId) {
        showAlert(
          "No active application found. Please create a personal profile first."
        );
        navigate("/personal-info");
        return;
      }

      // Filter out active evidence (not deleted)
      const activeEvidence = evidence.filter(
        (evidence) => !deletedEvidence.has(evidence.id)
      );

      // Prepare evidence for submission
      const evidenceToSubmit = await Promise.all(
        activeEvidence.map(async (evidence, index) => {
          let fileData = null;

          if (files[index]) {
            try {
              const base64Data = await fileToBase64(files[index]);
              fileData = {
                filename: files[index].name,
                content_type: files[index].type,
                base64_content: base64Data,
              };
            } catch (error) {
              console.error(`Error converting file to base64:`, error);
            }
          }

          return {
            id: evidence.id,
            ...prepareEvidenceData(evidence, fileData),
            application: parseInt(applicationId),
          };
        })
      );

      // Separate evidence into updates and creates
      const evidenceToUpdate = evidenceToSubmit.filter((e) => e.id);
      const evidenceToCreate = evidenceToSubmit.filter((e) => !e.id);

      // Handle updates
      if (evidenceToUpdate.length > 0) {
        const updateResponse = await protectedApi.patch(
          `/api/evidence/`,
          evidenceToUpdate,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );
        console.log("Update response:", updateResponse.data);
      }

      // Handle creates
      if (evidenceToCreate.length > 0) {
        const createResponse = await protectedApi.post(
          "/api/evidence/",
          evidenceToCreate,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );
        console.log("Create response:", createResponse.data);
      }

      // Handle deletions
      if (deletedEvidence.size > 0) {
        const deletePromises = Array.from(deletedEvidence).map((evidenceId) =>
          protectedApi.delete(`/api/evidence/${evidenceId}/`, {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          })
        );
        await Promise.all(deletePromises);
      }

      if (action === "continue") {
        handleComplete("evidence");
        navigate("/future-plans");
      } else {
        handleComplete("evidence");
        showAlert("Evidence saved successfully!");
      }
    } catch (error) {
      if (handleAuthError(error, navigate)) return;
      console.error("Error submitting form:", error);
      if (error.response?.data) {
        const errorMessage = Array.isArray(error.response.data)
          ? error.response.data.map((err) => err.error).join("\n")
          : error.response.data.error || "Unknown error";
        showAlert(`Error: ${errorMessage}`);
      } else {
        showAlert(error.message || "Error saving evidence");
      }
    }
  };

  const handleSave = (e) => handleFormSubmit(e, "save");
  const handleContinue = (e) => handleFormSubmit(e, "continue");

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const validFiles = newFiles.filter((file) => {
      if (file.type === "application/pdf") {
        return true;
      }
      showAlert(`${file.name} is not a PDF file`);
      return false;
    });

    if (validFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
      setFileNames((prevNames) => [
        ...prevNames,
        ...validFiles.map((f) => f.name),
      ]);
    }
  };

  const removeFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    setFileNames((prevNames) =>
      prevNames.filter((_, index) => index !== indexToRemove)
    );
  };

  const addNewEvidence = () => {
    setEvidence((prevEvidence) => [
      ...prevEvidence,
      {
        category: "",
        sub_category: "",
        received_date: "",
        expired_date: "",
        status: "",
        name: "",
        issuing_organization: "",
        brief_introduction: "",
        domain: "",
        level: "",
        supporting_doc: null,
        fileName: "",
      },
    ]);
    setOpenEvidence((prevEvidence) => [...prevEvidence, true]);
    setErrors((prevErrors) => [...prevErrors, {}]);
  };

  const toggleEvidence = (index) => {
    setOpenEvidence((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleDeleteEvidence = async (index) => {
    try {
      const evidenceToDelete = evidence[index];

      // Don't allow deletion if it's the last evidence
      if (evidence.length <= 1) {
        showAlert("You must have at least one evidence.");
        return;
      }

      // Make DELETE request if the evidence has an ID
      if (evidenceToDelete.id) {
        await protectedApi.delete(`/api/evidence/${evidenceToDelete.id}/`, {
          headers: {
            "X-Application-ID": localStorage.getItem("applicationId"),
          },
        });
        setDeletedEvidence((prev) => new Set([...prev, evidenceToDelete.id]));
      }

      // Update local state
      setEvidence((prev) => prev.filter((_, i) => i !== index));
      setOpenEvidence((prev) => prev.filter((_, i) => i !== index));
      setErrors((prev) => prev.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting evidence:", error);
      showAlert("Failed to delete evidence. Please try again.");
    }
  };

  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      setEvidence((prevEvidence) => {
        const updatedEvidence = [...prevEvidence];
        updatedEvidence[index] = {
          ...updatedEvidence[index],
          supporting_doc: file,
        };
        return updatedEvidence;
      });
    }
  };

  // Add useEffect to fetch existing evidence
  useEffect(() => {
    const fetchExistingApplication = async () => {
      showLoading("Fetching evidence...");
      try {
        const applicationId = localStorage.getItem("applicationId");
        if (!applicationId) {
          showAlert("No application found, please create one in Personal Info");
          return;
        }

        console.log("[GET] Fetching evidence for application:", applicationId);
        const response = await protectedApi.get("/api/evidence/", {
          headers: {
            "X-Application-ID": applicationId,
          },
        });
        console.log("[GET] Evidence response:", response.data);

        if (response.data?.results?.length > 0) {
          // Filter active evidence (not deleted)
          const activeEvidence = response.data.results.filter(
            (evidence) => !evidence.is_deleted
          );
          console.log("[GET] Active evidence:", activeEvidence);

          if (activeEvidence.length > 0) {
            // Map evidence to form structure
            const evidenceData = activeEvidence.map((evidence) => ({
              id: evidence.id,
              category: evidence.category || "",
              sub_category: evidence.sub_category || "",
              received_date: evidence.received_date || "",
              expired_date: evidence.expired_date || "",
              status: evidence.status || "",
              name: evidence.name || "",
              issuing_organization: evidence.issuing_organization || "",
              brief_introduction: evidence.brief_introduction || "",
              domain: evidence.domain || "",
              level: evidence.level || "",
              supporting_doc: evidence.supporting_doc || null,
            }));

            console.log("[GET] Mapped evidence data:", evidenceData);
            setEvidence(evidenceData);
            setOpenEvidence(new Array(evidenceData.length).fill(true));
            setErrors(new Array(evidenceData.length).fill({}));

            // Handle files if they exist
            const existingFiles = evidenceData
              .map((evidence) => evidence.supporting_doc)
              .filter(Boolean);

            console.log("[GET] Existing files:", existingFiles);
            setFileNames(existingFiles.map((file) => file.split("/").pop()));
          }
        }
      } catch (error) {
        console.error("[GET] Error fetching evidence:", error);
        showAlert("Error fetching evidence");
      } finally {
        hideLoading();
      }
    };

    fetchExistingApplication();
  }, [navigate]); // Empty dependency array to run only once on mount

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const prepareFileData = async (file) => {
    if (!file) return null;
    const base64Data = await fileToBase64(file);
    return {
      filename: file.name,
      content_type: file.type,
      base64_content: base64Data,
    };
  };

  const prepareEvidenceData = (evidence, fileData) => ({
    category: evidence.category,
    sub_category: evidence.sub_category,
    received_date: evidence.received_date,
    expired_date: evidence.expired_date,
    status: evidence.status,
    name: evidence.name,
    issuing_organization: evidence.issuing_organization,
    brief_introduction: evidence.brief_introduction,
    domain: evidence.domain,
    level: evidence.level,
    supporting_doc: fileData || null,
  });

  const handleEvidenceUpdate = async (evidence, index) => {
    try {
      const applicationId = localStorage.getItem("applicationId");
      if (!applicationId) {
        throw new Error("No active application found");
      }

      const fileData = files[index]
        ? await prepareFileData(files[index])
        : null;
      const evidenceData = prepareEvidenceData(evidence, fileData);

      if (evidence.id) {
        await protectedApi.patch(
          `/api/evidence/${evidence.id}/`,
          evidenceData,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );
      } else {
        const response = await protectedApi.post(
          "/api/evidence/",
          {
            ...evidenceData,
            application: applicationId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );

        // Update local state with new evidence ID
        setEvidence((prevEvidence) => {
          const updatedEvidence = [...prevEvidence];
          updatedEvidence[index] = { ...evidence, id: response.data.id };
          return updatedEvidence;
        });
      }

      return true;
    } catch (error) {
      console.error("Error updating evidence:", error);
      throw error;
    }
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.createProfile}>
          <div className={styles.wrapper}>
            <h1 className={styles.createYourProfile}>Create Your Profile</h1>
            <form onSubmit={handleContinue}>
              <div className={styles.formContainer}>
                {evidence.map((evidenceItem, index) => (
                  <div key={index} className={styles.formSection}>
                    <div className={styles.evidenceHeader}>
                      <h2 className={styles.evidenceTitle}>
                        {`Evidence ${index + 1}`}
                      </h2>
                      <div className={styles.headerActions}>
                        {index === 0 && (
                          <svg
                            className={styles.deleteIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteEvidence(index);
                            }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                          >
                            <path
                              d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <svg
                          className={`${styles.dropdownIcon} ${
                            openEvidence[index] ? styles.open : ""
                          }`}
                          onClick={() => toggleEvidence(index)}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M6 9l6 6 6-6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    <div
                      className={`${styles.evidenceContent} ${
                        openEvidence[index] ? styles.open : ""
                      }`}
                    >
                      {index === 0 && (
                        <div className={styles.sectionHeader}>
                          <Stepper
                            currentStep={6}
                            totalSteps={7}
                            progressWidth={28}
                          />
                        </div>
                      )}

                      {/* Original Input Fields */}
                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <CustomDropdown
                            name="category"
                            value={evidenceItem.category}
                            onChange={(e) => handleMainCategoryChange(e, index)}
                            options={MAIN_CATEGORIES}
                            placeholder="Select Category"
                            className={
                              errors[index]?.category ? styles.errorInput : ""
                            }
                            required
                          />
                          {errors[index]?.category && (
                            <span className={styles.errorMessage}>
                              {errors[index].category}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <CustomDropdown
                            name="sub_category"
                            value={evidenceItem.sub_category}
                            onChange={(e) => handleSubCategoryChange(e, index)}
                            options={
                              EVIDENCE_CATEGORIES[evidenceItem.category] || []
                            }
                            placeholder="Select Sub-Category"
                            className={
                              errors[index]?.sub_category
                                ? styles.errorInput
                                : ""
                            }
                            disabled={!evidenceItem.category}
                            required
                          />
                          {errors[index]?.sub_category && (
                            <span className={styles.errorMessage}>
                              {errors[index].sub_category}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <CustomDropdown
                            name="status"
                            value={evidenceItem.status}
                            onChange={(e) => handleChange(e, index)}
                            options={STATUS_OPTIONS}
                            placeholder="Select Status"
                            className={
                              errors[index]?.status ? styles.errorInput : ""
                            }
                            required
                          />
                          {errors[index]?.status && (
                            <span className={styles.errorMessage}>
                              {errors[index].status}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <CustomDropdown
                            name="level"
                            value={evidenceItem.level}
                            onChange={(e) => handleChange(e, index)}
                            options={IMPACT_LEVEL_OPTIONS}
                            placeholder="Select Level"
                            className={
                              errors[index]?.level ? styles.errorInput : ""
                            }
                            required
                          />
                          {errors[index]?.level && (
                            <span className={styles.errorMessage}>
                              {errors[index].level}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <label className={styles.inputLabel}>
                            Received Date
                          </label>
                          <input
                            type="date"
                            name="received_date"
                            value={evidenceItem.received_date}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.received_date
                                ? styles.errorInput
                                : ""
                            }`}
                          />
                          {errors[index]?.received_date && (
                            <span className={styles.errorMessage}>
                              {errors[index].received_date}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <label className={styles.inputLabel}>
                            Expired Date
                          </label>
                          <input
                            type="date"
                            name="expired_date"
                            value={evidenceItem.expired_date}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.expired_date
                                ? styles.errorInput
                                : ""
                            }`}
                          />
                          {errors[index]?.expired_date && (
                            <span className={styles.errorMessage}>
                              {errors[index].expired_date}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="name"
                            placeholder="Evidence Name"
                            value={evidenceItem.name}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.name ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.name && (
                            <span className={styles.errorMessage}>
                              {errors[index].name}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="issuing_organization"
                            placeholder="Issuing Organization"
                            value={evidenceItem.issuing_organization}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.issuing_organization
                                ? styles.errorInput
                                : ""
                            }`}
                            required
                          />
                          {errors[index]?.issuing_organization && (
                            <span className={styles.errorMessage}>
                              {errors[index].issuing_organization}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="domain"
                            placeholder="Domain"
                            value={evidenceItem.domain}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.domain ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.domain && (
                            <span className={styles.errorMessage}>
                              {errors[index].domain}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.inputWrapper}>
                        <textarea
                          name="brief_introduction"
                          placeholder="Brief Introduction (i.e., area, criteria, audience, etc.)"
                          value={evidenceItem.brief_introduction}
                          onChange={(e) => handleChange(e, index)}
                          className={`${styles.textArea} ${
                            errors[index]?.brief_introduction
                              ? styles.errorInput
                              : ""
                          }`}
                          required
                        />
                        {errors[index]?.brief_introduction && (
                          <span className={styles.errorMessage}>
                            {errors[index].brief_introduction}
                          </span>
                        )}
                      </div>

                      <div className={styles.fileUploadContainer}>
                        <input
                          type="file"
                          accept=".pdf"
                          onChange={handleFileChange}
                          className={styles.fileInput}
                          id="fileUpload"
                          multiple
                        />
                        <label
                          htmlFor="fileUpload"
                          className={styles.fileInputLabel}
                        >
                          Upload Supporting Documents (PDF)
                        </label>

                        <div className={styles.uploadedFiles}>
                          {fileNames.map((name, fileIndex) => (
                            <div key={fileIndex} className={styles.fileItem}>
                              <span>{name}</span>
                              <button
                                type="button"
                                onClick={() => removeFile(fileIndex)}
                                className={styles.removeFile}
                                aria-label="Remove file"
                              >
                                ×
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Form Actions - Outside the projects loop */}
                <div className={styles.formActions}>
                  <button
                    type="button"
                    className={styles.secondaryButton}
                    onClick={addNewEvidence}
                  >
                    Add Another Evidence
                  </button>
                  <div className={styles.navButtons}>
                    <Button
                      variant="secondary"
                      onClick={usePreviousNavigation()}
                    >
                      Previous
                    </Button>
                    <Button variant="secondary" onClick={handleSave}>
                      Save
                    </Button>
                    <Button variant="primary" onClick={handleContinue}>
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EvidenceInfoPage;
