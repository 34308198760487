import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./EmailVerification.module.css";
import logo from "../assets/images/logo.svg";
import { authApi } from "../services/api";

function EmailVerificationSuccess() {
  const { token } = useParams();
  const [verificationStatus, setVerificationStatus] = useState("verifying");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await authApi.get(`/api/auth/verify-email/${token}/`);
        setVerificationStatus("success");
        setMessage(response.data.message);
      } catch (error) {
        setVerificationStatus("error");
        setMessage(
          error.response?.data?.error ||
            "Verification failed. Please try again."
        );
      }
    };

    verifyEmail();
  }, [token]);

  if (verificationStatus === "verifying") {
    return (
      <div className={styles.verificationSuccess}>
        <div className={styles.card}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <div className={styles.iconContainer}>
            <i className="fas fa-spinner fa-spin"></i>
          </div>
          <h1 className={styles.heading}>Verifying Email...</h1>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.verificationSuccess}>
      <div className={styles.card}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <div className={styles.iconContainer}>
          <i
            className={`fas ${
              verificationStatus === "success"
                ? "fa-check-circle"
                : "fa-times-circle"
            }`}
          ></i>
        </div>
        <h1 className={styles.heading}>
          {verificationStatus === "success"
            ? "Email Verified Successfully!"
            : "Verification Failed"}
        </h1>
        <div className={styles.message}>
          {message ||
            (verificationStatus === "success"
              ? "Your email has been successfully verified. You can now log in to your account."
              : "There was a problem verifying your email. Please try again or contact support.")}
        </div>
        <Link to="/login" className={styles.loginButton}>
          {verificationStatus === "success"
            ? "Log In to Your Account"
            : "Back to Login"}
        </Link>
      </div>
    </div>
  );
}

export default EmailVerificationSuccess;
