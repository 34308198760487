import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./Dashboard.module.css";
import Sidebar from "../common/Sidebar";
import Header from "../common/Header2";
import { FaChevronRight } from "react-icons/fa";
// import { useAuth } from "../../contexts/AuthContext";
// import protectedApi from "../../services/protectedApi";
import ProposedEndeavor from "../features/proposed_endeavor.js";
import { ProgressBarContext } from "./ProgressBar.js";

function Dashboard() {
  const { completedItems, statusBoxes, handleComplete } = useContext(ProgressBarContext);
  const [expandedSections, setExpandedSections] = useState({
    education: false,
    employment: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // 计算每个box的进度条
  const calculateBoxProgress = (items) => {
    const totalTime = items.reduce((acc, item) => {
      if (item.isDropdown) {
        return (
          acc +
          item.subItems.reduce((subAcc, subItem) => subAcc + subItem.time, 0)
        );
      }
      return acc + (item.time || 0);
    }, 0);

    const completedTime = items.reduce((acc, item) => {
      if (item.isDropdown) {
        return (
          acc +
          item.subItems.reduce(
            (subAcc, subItem) =>
              completedItems[subItem.id] ? subAcc + subItem.time : subAcc,
            0
          )
        );
      }
      return acc + (completedItems[item.id] ? item.time : 0);
    }, 0);

    return completedTime > 0 ? Math.round((completedTime / totalTime) * 100) : 0;
  };

  // 计算总进度条
  const calculateProgress = () => {
    const totalTime = statusBoxes.reduce(
      (acc, box) =>
        acc +
        box.items.reduce((boxAcc, item) => {
          if (item.isDropdown) {
            return (
              boxAcc +
              item.subItems.reduce(
                (subAcc, subItem) => subAcc + subItem.time,
                0
              )
            );
          }
          return boxAcc + (item.time || 0);
        }, 0),
      0
    );

    const completedTime = statusBoxes.reduce(
      (acc, box) =>
        acc +
        box.items.reduce((boxAcc, item) => {
          if (item.isDropdown) {
            return (
              boxAcc +
              item.subItems.reduce(
                (subAcc, subItem) =>
                  completedItems[subItem.id] ? subAcc + subItem.time : subAcc,
                0
              )
            );
          }
          return boxAcc + (completedItems[item.id] ? item.time : 0);
        }, 0),
      0
    );

    return completedTime > 0 ? Math.round((completedTime / totalTime) * 100) : 0;
  };

  const resourceLinks = [
    {
      title: "What are the basic requirements for NIW?",
      link: "/resources/niw-requirements",
    },
    {
      title: "7 most common mistakes to avoid",
      link: "/resources/common-mistakes",
    },
    {
      title: "DIY v.s. attorney-assisted filing",
      link: "/resources/diy-vs-attorney",
    },
  ];

  const renderItem = (item, itemIndex) => {
    if (item.isDropdown) {
      const isExpanded = expandedSections[item.section];
      return (
        <div key={itemIndex}>
          <div
            className={`${styles.item} ${styles.dropdownHeader}`}
            onClick={() => toggleSection(item.section)}
          >
            <span className={styles.itemName}>
              {item.name}
              <span
                className={`${styles.arrow} ${
                  isExpanded ? styles.expanded : ""
                }`}
              >
                ▼
              </span>
            </span>
            {item.time_str && <span className={styles.itemTime}>{item.time_str}</span>}
          </div>
          {isExpanded && (
            <div className={styles.subItems}>
              {item.subItems.map((subItem, subIndex) => (
                <Link
                  to={subItem.link}
                  key={subIndex}
                  className={`${styles.item} ${styles.subItem}`}
                >
                  <span className={styles.itemName}>{subItem.name}</span>
                </Link>
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <Link to={item.link} key={itemIndex} className={styles.item}>
        <span className={styles.itemName}>{item.name}</span>
        {item.time_str && <span className={styles.itemTime}>{item.time_str}</span>}
      </Link>
    );
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <Sidebar />
        <main className={styles.main}>
          <div className={styles.dashboardHeader}>
            <div className={styles.headerTop}>
              <h1 className={styles.title}>Overview</h1>
              <div className={styles.overallProgress}>
                <div className={styles.progressInfo}>
                  <span className={styles.progressLabel}>
                    Overall Progress:{" "}
                  </span>
                  <span className={styles.progressPercentage}>
                    {calculateProgress()}%
                  </span>
                </div>
                <div className={styles.progressBarWrapper}>
                  <div className={styles.progressBar}>
                    <div
                      className={styles.progressFill}
                      style={{ width: `${calculateProgress()}%` }} // 计算总进度条
                    >
                      <div className={styles.progressGlow}></div>
                    </div>
                    <div className={styles.progressSteps}>
                      {[0, 25, 50, 75, 100].map((step) => (
                        <div
                          key={step}
                          className={`${styles.progressStep} ${
                            calculateProgress() >= step ? styles.completed : ""
                          }`}
                        >
                          <div className={styles.stepDot}></div>
                          <span className={styles.stepLabel}>{step}%</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.statusBoxes}>
            {statusBoxes.map((box, index) => (
              <div key={index} className={styles.statusBox}>
                <div className={styles.boxHeader}>
                  <h2>{box.title}</h2>
                  <div className={styles.progressBar}>
                    <div className={styles.progressText}>
                      {calculateBoxProgress(box.items)}%
                    </div>
                    <div
                      className={styles.progressFill}
                      style={{ width: `${calculateBoxProgress(box.items)}%` }} // 计算每个box的进度条
                    />
                  </div>
                </div>
                <div className={styles.itemList}>
                  {box.items.map((item, itemIndex) =>
                    renderItem(item, itemIndex)
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className={styles.resourcesSection}>
            <h2 className={styles.resourcesTitle}>Tips and Resources</h2>
            <div className={styles.resourcesGrid}>
              {resourceLinks.map((resource, index) => (
                <Link
                  key={index}
                  to={resource.link}
                  className={styles.resourceCard}
                >
                  <span>{resource.title}</span>
                  <FaChevronRight className={styles.resourceArrow} />
                </Link>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
