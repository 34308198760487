import React, { createContext, useState, useContext } from "react";
import Loading from "./Loading";

const LoadingContext = createContext();

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const showLoading = (message = "Loading...") => {
    setMessage(message);
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
    setMessage("");
  };

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      {children}
      {loading && <Loading message={message} />}
    </LoadingContext.Provider>
  );
}

export const useLoading = () => useContext(LoadingContext);
