import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./rfe_response.module.css";
import Header2 from "../common/Header2";
import Sidebar from "../common/Sidebar";
import { checkUsageLimits } from "../../services/crudHelpers";
import { useAlert } from "../common/AlertContext";
import { ProgressBarContext } from "../dashboard/ProgressBar.js";

function RfeResponse() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { handleComplete } = useContext(ProgressBarContext);
  const [files, setFiles] = React.useState({
    response: null,
  });

  useEffect(() => {
    // Set the package type in localStorage when component mounts
    localStorage.setItem("selectedPackage", "rfe");
  }, []);

  const handleFileUpload = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [type]: file,
      }));
    }
  };

  const startRfeProcess = () => {
    // Begin the RFE flow by navigating to personal info
    navigate("/personal-info", {
      state: {
        flow: "rfe",
        steps: [
          { name: "Personal Information", path: "/personal-info" },
          { name: "Proposed Endeavor", path: "/proposed-endeavor" },
          { name: "Recommendations", path: "/recommendations" },
          { name: "Petition Letter", path: "/petition-letter" },
        ],
        currentStep: 0,
      },
    });
  };

  const handleGenerate = async () => {
    const usageCheck = await checkUsageLimits("RFE Response", showAlert);

    if (!usageCheck.success) {
      if (usageCheck.action === "redirect") {
        navigate("/plans");
      }
      showAlert(usageCheck.message);
      return;
    }

    handleComplete("rfe-response");
    // Continue with generation logic
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>RFE Response</h1>
            <div className={styles.rfeAnalysisCard}>
              <div className={styles.rfeAnalysisHeader}>
                <div className={styles.headerLeft}>
                  <span className={styles.aiIcon}>✨</span>
                  <span>AI Generate Request for Evidence Response</span>
                </div>
              </div>

              <div className={styles.rfeContent}>
                <p>
                  Let's help you prepare your RFE response. We'll guide you
                  through the following steps:
                </p>
                <p className={styles.text}>
                  1. Please provide the following information to get started:
                </p>
                <ol
                  className={styles.stepsList}
                  style={{ listStyleType: "lower-alpha" }}
                >
                  <li>Input Your Background Information</li>
                  <li>
                    Show us your proposed endeavor of the initial filing letter
                  </li>
                  <li>
                    Tell us about your Referer Information and recommendation
                    letters
                  </li>
                  <li>Tell us about your Petition Letter</li>
                </ol>
                <button
                  className={styles.startButton}
                  onClick={startRfeProcess}
                >
                  Start Background Information
                </button>
                <div className={styles.rfeAnalysis}></div>
                <p className={styles.text}>
                  2. Please upload your initial filing response from USCIS.
                </p>
                <div className={styles.uploadBox}>
                  <input
                    type="file"
                    id="responseLetter"
                    accept=".pdf,.doc,.docx"
                    className={styles.uploadInput}
                    onChange={(e) => handleFileUpload(e, "response")}
                  />
                </div>
                <div className={styles.rfeResponse}>
                  <p className={styles.text}>
                    3. Click the button below to generate your RFE response.
                  </p>
                  <button
                    className={styles.startButton}
                    onClick={handleGenerate}
                  >
                    Generate RFE Response
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RfeResponse;
