import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.css";
import {
  authApi,
  getCsrfToken,
  protectedApi,
  handleAuthError,
} from "../../services/api";
import { ReactComponent as DashboardIcon } from "../../assets/images/dashboard.svg";
import { ReactComponent as ApplicationIcon } from "../../assets/images/application.svg";
import { ReactComponent as ProfileIcon } from "../../assets/images/profile.svg";
import { ReactComponent as ProposedEndeavorsIcon } from "../../assets/images/proposed_endeavor.svg";
import { ReactComponent as RecommendationsIcon } from "../../assets/images/application.svg";
import { ReactComponent as PetitionLetterIcon } from "../../assets/images/application.svg";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleLogout = async () => {
    try {
      // Make the logout request

      const response = await authApi.post("/api/auth/logout/");

      if (response.status === 200) {
        // Clear storage
        localStorage.clear();
        sessionStorage.clear();

        // Clear cookies without specifying path or domain
        document.cookie.split(";").forEach((cookie) => {
          const [name] = cookie.split("=");
          const trimmedName = name.trim();
          document.cookie = `${trimmedName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; samesite=lax`;
        });
        navigate("/login");
      }
    } catch (error) {
      console.error("Logout failed:", error);
      if (error.response?.status === 403) {
        // Clear storage
        localStorage.clear();
        sessionStorage.clear();

        // Attempt to clear cookies without specifying path or domain
        document.cookie.split(";").forEach((cookie) => {
          const [name] = cookie.split("=");
          const trimmedName = name.trim();
          document.cookie = `${trimmedName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; samesite=lax`;
        });
      }
      navigate("/login");
    }
  };

  return (
    <div className={styles.menu}>
      {/* <div className={styles.sidebarHeader}>
        <img src={logo} alt="TurboBe Logo" className={styles.logo} />
        <h1 className={styles.sidebarTitle}>TurboBe</h1>
      </div> */}

      <div className={styles.menuItems}>
        <div
          className={`${styles.listManu} ${
            isActive("/dashboard") ? styles.active : ""
          }`}
          onClick={() => navigate("/dashboard")}
        >
          <div className={styles.content}>
            <DashboardIcon className={styles.icons} />
            <div className={styles.text2}>Dashboard</div>
          </div>
        </div>

        {/* <div>
          <div
            className={`${styles.listManu} ${
              isActive("/application") ? styles.active : ""
            }`}
            onClick={toggleDropdown}
          >
            <div className={styles.content}>
              <ApplicationIcon className={styles.icons} />
              <div className={styles.text2}>Application</div>
            </div>
          </div>

          {isDropdownOpen && (
            <div className={styles.dropdown}>
              <div
                className={`${styles.dropdownItem} ${
                  isActive("/application/profile") ? styles.active : ""
                }`}
                onClick={() => navigate("/application/profile")}
              >
                <ProfileIcon className={styles.dropdownIcon} />
                <span>Profile</span>
              </div>
              <div
                className={`${styles.dropdownItem} ${
                  isActive("/application/proposed-endeavors")
                    ? styles.active
                    : ""
                }`}
                onClick={() => navigate("/application/proposed-endeavors")}
              >
                <ProposedEndeavorsIcon className={styles.dropdownIcon} />
                <span>Proposed Endeavors</span>
              </div>
              <div
                className={`${styles.dropdownItem} ${
                  isActive("/application/recommendations") ? styles.active : ""
                }`}
                onClick={() => navigate("/application/recommendations")}
              >
                <RecommendationsIcon className={styles.dropdownIcon} />
                <span>Recommendations</span>
              </div>
              <div
                className={`${styles.dropdownItem} ${
                  isActive("/application/petition-letter") ? styles.active : ""
                }`}
                onClick={() => navigate("/application/petition-letter")}
              >
                <PetitionLetterIcon className={styles.dropdownIcon} />
                <span>Petition Letter</span>
              </div>
            </div>
          )}
        </div> */}
        <div
          className={`${styles.listManu} ${
            isActive("/plans") ? styles.active : ""
          }`}
          onClick={() => navigate("/plans")}
        >
          <div className={styles.content}>
            <ApplicationIcon className={styles.icons} />
            <div className={styles.text2}>Plans & Billing</div>
          </div>
        </div>
        <div
          className={`${styles.listManu} ${
            isActive("/account-settings") ? styles.active : ""
          }`}
          onClick={() => navigate("/account-settings")}
        >
          <div className={styles.content}>
            <ProfileIcon className={styles.icons} />
            <div className={styles.text2}>Account Settings</div>
          </div>
        </div>
      </div>

      <div className={styles.sidebarFooter}>
        <div className={styles.userInfo}>
          {/* <div className={styles.userEmail}>user@example.com</div> */}
          <button className={styles.logoutButton} onClick={handleLogout}>
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
