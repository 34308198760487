import React, { useState } from "react";
import styles from "./ForgotPassword.module.css";
import logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { authApi, handleAuthError } from "../services/api";
function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError("");
    }

    try {
      const response = await Promise.race([
        authApi.post("/api/auth/forgot-password/", {
          email,
        }),

        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Request timeout")), 10000)
        ),
      ]);
      console.log(response);

      setSuccessMessage(
        "If the email is registered, a password reset link will be sent."
      );
      setEmail("");
    } catch (error) {
      console.error("Error sending password reset request:", error);
      if (error.response.status === 403) {
        handleAuthError(error);
      }
      setEmailError(
        error.message === "Request timeout"
          ? "Request timed out. Please check if the email is registered first."
          : error.response?.data?.message ||
              "Something went wrong. Please try again later."
      );
    }
  };

  return (
    <div className={styles.forgotPasswordContainer}>
      <div className={styles.navbarIcon}>
        <img src={logo} alt="Logo" />
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h2 className={styles.title}>Forgot Password</h2>
        {successMessage && (
          <div className={styles.successText}>{successMessage}</div>
        )}
        <div className={styles.inputContainer}>
          <label className={styles.label}>Email</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            placeholder="Enter your email address"
            className={styles.inputField}
            required
          />
          {emailError && <div className={styles.errorText}>{emailError}</div>}
        </div>
        <button type="submit" className={styles.buttonPrimary}>
          Send Reset Link
        </button>
      </form>
      <div className={styles.backToLoginLink}>
        <Link to="/login">Back to Login</Link>
      </div>
    </div>
  );
}

export default ForgotPassword;
