import React from "react";
import styles from "./Alert.module.css";

function Alert({ message, type = "error", onClose, onConfirm }) {
  return (
    <div className={styles.alertOverlay}>
      <div className={`${styles.alertContainer} ${styles[type]}`}>
        <p className={styles.alertMessage}>{message}</p>
        <div className={styles.alertButtons}>
          <button className={styles.confirmButton} onClick={onConfirm}>
            OK
          </button>
          <button className={styles.closeButton} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default Alert;
