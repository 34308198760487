import React from "react";
import styles from "./Loading.module.css";

function Loading({ message }) {
  return (
    <div className={styles.loadingOverlay}>
      <div className={styles.loadingContainer}>
        <div className={styles.loadingSpinner}></div>
        <p className={styles.loadingText}>{message}</p>
      </div>
    </div>
  );
}

export default Loading;
