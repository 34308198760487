import React, { createContext, useState, useContext } from "react";
import Alert from "./Alert";
const AlertContext = createContext();

export function AlertProvider({ children }) {
  const [alert, setAlert] = useState(null);

  const showAlert = (message, type = "error") => {
    return new Promise((resolve) => {
      setAlert({ message, type, resolve });
    });
  };

  const handleClose = () => {
    if (alert?.resolve) {
      alert.resolve(false);
    }
    setAlert(null);
  };

  const handleConfirm = () => {
    if (alert?.resolve) {
      alert.resolve(true);
    }
    setAlert(null);
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {alert && (
        <Alert
          message={alert.message}
          type={alert.type}
          onClose={handleClose}
          onConfirm={handleConfirm}
        />
      )}
    </AlertContext.Provider>
  );
}

export const useAlert = () => useContext(AlertContext);
