import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./recommender.module.css";
import Sidebar from "../common/Sidebar";
import Header2 from "../common/Header2";
import { protectedApi } from "../../services/api";
import { ProgressBarContext } from "../dashboard/ProgressBar.js";

function Recommender() {
  const navigate = useNavigate();
  const { handleComplete } = useContext(ProgressBarContext);
  const [recommenders, setRecommenders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchRecommenders = async () => {
    try {
      const response = await protectedApi.get("/api/referee/");
      if (response.status === 200) {
        const recommendersData = response.data.results || response.data;
        setRecommenders(
          Array.isArray(recommendersData) ? recommendersData : []
        );
        console.log(recommendersData);
      }
    } catch (error) {
      console.error("Error fetching recommenders:", error);
      setRecommenders([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRecommenders();

    // Set up refresh interval
    const refreshInterval = setInterval(() => {
      console.log("Refreshing recommenders data...");
      fetchRecommenders();
    }, 20000); // 20 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(refreshInterval);
  }, []); // Empty dependency array since fetchRecommenders is defined inside component

  const handleRecommenderClick = (refereeId) => {
    localStorage.setItem("refereeId", refereeId);
    navigate(`/recommender-config?ref=${refereeId}`);
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>Recommendation Letter</h1>
            <div className={styles.recommenderContainer}>
              {isLoading ? (
                <div className={styles.loadingContainer}>
                  <div className={styles.loadingSpinner} />
                  <p className={styles.loadingText}>
                    Loading your recommenders...
                  </p>
                  <p className={styles.loadingSubtext}>
                    Please wait while we fetch your data
                  </p>
                </div>
              ) : (
                <div className={styles.recommenderGrid}>
                  {recommenders.length > 0 &&
                    recommenders.map((recommender) => (
                      <div
                        key={recommender.id}
                        className={styles.recommenderBox}
                        onClick={() => handleRecommenderClick(recommender.id)}
                      >
                        <div className={styles.recommenderInfo}>
                          <h3>{`${recommender.first_name} ${recommender.last_name}`}</h3>
                          <p>{recommender.title}</p>
                          <p>{recommender.organization}</p>
                        </div>
                      </div>
                    ))}
                  <div className={styles.recommenderBox}>
                    <button
                      className={styles.addButton}
                      onClick={() => navigate("/recommender-config")}
                    >
                      <span className={styles.plusIcon}>+</span>
                      <span>Add Recommender</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recommender;
