import React, { useContext } from "react";
import styles from "./EmploymentInfo.module.css";
import { useNavigate } from "react-router-dom";
import Sidebar from "../common/Sidebar";
import Button from "../common/Button";
import Stepper from "../common/ProgressBar.js";
import CustomDropdown from "../common/Dropdown.js";
import { usePreviousNavigation } from "../../services/handlePrevious";
import { useCrudOperations } from "../../services/useCrudOperations";
import { handleAuthError } from "../../services/api.js";
import { useAlert } from "../common/AlertContext.js";
import {
  INDUSTRIES,
  COUNTRIES,
  ORGANIZATION_TYPES,
  EMPLOYMENT_TYPES,
} from "../../constants/dropdownConsts.js";
import Header2 from "../common/Header2";
import { ProgressBarContext } from "./ProgressBar.js";

function EmploymentInfoPage() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { handleComplete } = useContext(ProgressBarContext);

  // Only define the hide icon here since it doesn't need index
  const hideIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9L12 16L5 9"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const initialEmploymentState = {
    organization: "",
    organization_type: "",
    organization_industry: "",
    organization_website: "",
    country: "",
    state: "",
    city: "",
    start_date: "",
    end_date: "",
    is_current: false,
    job_type: "",
    job_title: "",
    annual_salary: "",
    working_hours_per_week: "",
    job_summary: "",
    required_skills: "",
  };

  // Transform functions for the CRUD operations
  const transformFetchedData = (data) => ({
    ...data,
    // Any specific transformations needed for fetched data
  });

  const transformSubmitData = (item) => ({
    ...item,
    organization_website: item.organization_website || "https://example.com",
    annual_salary: parseFloat(item.annual_salary) || 0,
    working_hours_per_week: parseFloat(item.working_hours_per_week) || 0,
  });

  const {
    items: employment,
    setItems: setEmployment,
    errors,
    setErrors,
    openItems: openEmployments,
    setOpenItems: setOpenEmployments,
    deletedItems: deletedEmployments,
    handleSubmit,
    addNewItem: addNewEmployment,
    deleteItem: handleDeleteEmployment,
  } = useCrudOperations({
    endpoint: "/api/professionalhistory/",
    initialState: initialEmploymentState,
    transformFetchedData,
    transformSubmitData,
  });

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    setEmployment((prevEmployment) => {
      const updatedEmployment = [...prevEmployment];
      updatedEmployment[index] = {
        ...updatedEmployment[index],
        [name]: type === "checkbox" ? checked : value,
      };
      return updatedEmployment;
    });
  };

  const toggleEmployment = (index) => {
    setOpenEmployments((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();

    // Validate all employment entries
    const validationResults = employment.map(validateEmploymentData);
    const hasErrors = validationResults.some((result) => !result.isValid);

    if (hasErrors) {
      // Update the errors state with all validation errors
      const newErrors = validationResults.map((result) => result.errors);
      setErrors(newErrors);
      showAlert("Please fill in all required fields correctly");
      return;
    }

    const result = await handleSubmit(action);

    if (result.success) {
      if (action === "continue") {
        handleComplete("employment-history");
        navigate("/project-info");
      } else {
        handleComplete("employment-history");
        showAlert("Employment information saved successfully!");
      }
    } else {
      if (handleAuthError(result.error, navigate)) return;
      showAlert(result.error || "An error occurred while saving");
    }
  };

  const handleSave = (e) => handleFormSubmit(e, "save");
  const handleContinue = (e) => handleFormSubmit(e, "continue");

  // Add this validation function after the handlePrevious function
  const validateEmploymentData = (employment) => {
    const errors = {};

    // Separate fields by type
    const stringFields = [
      "organization",
      "organization_type",
      "organization_industry",
      "country",
      "state",
      "city",
      "job_title",
      "job_type",
      "job_summary",
      "required_skills",
    ];

    const dateFields = ["start_date", "end_date"];

    const numericFields = ["annual_salary", "working_hours_per_week"];

    // Validate string fields
    stringFields.forEach((field) => {
      if (!employment[field] || String(employment[field]).trim() === "") {
        errors[field] = "This field is required";
      }
    });

    // Validate numeric fields
    numericFields.forEach((field) => {
      if (!employment[field] || isNaN(parseFloat(employment[field]))) {
        errors[field] = "This field is required";
      }
    });

    // Validate dates
    dateFields.forEach((field) => {
      if (
        !employment.is_current &&
        (!employment[field] || employment[field] === "")
      ) {
        errors[field] = "This field is required";
      }
    });

    // Special validation for end_date
    if (
      !employment.is_current &&
      (!employment.end_date || employment.end_date === "")
    ) {
      errors.end_date = "End date is required when not currently employed";
    }

    // Validate dates order
    if (
      employment.start_date &&
      employment.end_date &&
      !employment.is_current
    ) {
      const startDate = new Date(employment.start_date);
      const endDate = new Date(employment.end_date);
      if (endDate < startDate) {
        errors.end_date = "End date cannot be before start date";
      }
    }

    // Update URL validation to only check if a value is provided
    if (
      employment.organization_website &&
      employment.organization_website.trim() !== ""
    ) {
      const urlPattern =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      if (!urlPattern.test(employment.organization_website)) {
        errors.organization_website = "Please enter a valid URL";
      }
    }

    // Validate numeric fields
    if (
      isNaN(parseFloat(employment.annual_salary)) ||
      parseFloat(employment.annual_salary) <= 0
    ) {
      errors.annual_salary = "Please enter a valid salary amount";
    }

    if (
      isNaN(parseFloat(employment.working_hours_per_week)) ||
      parseFloat(employment.working_hours_per_week) <= 0 ||
      parseFloat(employment.working_hours_per_week) > 168
    ) {
      errors.working_hours_per_week =
        "Please enter valid working hours (1-168)";
    }

    return { isValid: Object.keys(errors).length === 0, errors };
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.createProfile}>
          <div className={styles.wrapper}>
            <h1 className={styles.createYourProfile}>Create Your Profile</h1>
            <form onSubmit={handleContinue}>
              <div className={styles.formContainer}>
                {employment.map((emp, index) => (
                  <div key={index} className={styles.formSection}>
                    {/* Employment Header */}
                    <div className={styles.projectHeader}>
                      <h2 className={styles.projectTitle}>
                        {`Employment History - ${emp.organization}` ||
                          `Employment History  ${index + 1}`}
                      </h2>
                      <div className={styles.headerActions}>
                        <svg
                          className={styles.deleteIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteEmployment(index);
                          }}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <svg
                          className={`${styles.dropdownIcon} ${
                            openEmployments[index] ? styles.open : ""
                          }`}
                          onClick={() => toggleEmployment(index)}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M6 9l6 6 6-6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    {openEmployments[index] && (
                      <div className={styles.formContent}>
                        {index === 0 && (
                          <Stepper
                            currentStep={4}
                            totalSteps={7}
                            progressWidth={28}
                          />
                        )}

                        <div className={styles.formRow}>
                          <div className={styles.inputWrapper}>
                            <input
                              type="text"
                              name="organization"
                              placeholder="Organization/Company Name"
                              value={emp.organization}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.organization
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.organization && (
                              <span className={styles.errorMessage}>
                                {errors[index].organization}
                              </span>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            <CustomDropdown
                              name="organization_type"
                              value={emp.organization_type}
                              onChange={(e) => handleChange(e, index)}
                              options={ORGANIZATION_TYPES}
                              placeholder="Select Category"
                              error={errors[index]?.organization_type}
                              // label="Industry"
                              required
                            />
                          </div>
                          <div className={styles.inputWrapper}>
                            <CustomDropdown
                              name="organization_industry"
                              value={emp.organization_industry}
                              onChange={(e) => handleChange(e, index)}
                              options={INDUSTRIES}
                              placeholder="Select Industry"
                              error={errors[index]?.organization_industry}
                              // label="Industry"
                              required
                            />
                          </div>
                          <div className={styles.inputWrapper}>
                            <input
                              type="url"
                              name="organization_website"
                              placeholder="Organization Website"
                              value={emp.organization_website}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.organization_website
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.organization_website && (
                              <span className={styles.errorMessage}>
                                {errors[index].organization_website}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Continue with other form fields following the same pattern */}
                        {/* Organization Details */}
                        <div className={styles.formRow}>
                          <div className={styles.inputWrapper}>
                            <CustomDropdown
                              name="country"
                              value={emp.country}
                              onChange={(e) => handleChange(e, index)}
                              options={COUNTRIES}
                              placeholder="Select Country"
                              error={errors[index]?.country}
                              // label="Country"
                              required
                            />
                          </div>
                          <div className={styles.inputWrapper}>
                            <input
                              type="text"
                              name="state"
                              placeholder="State"
                              value={emp.state}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.state ? styles.errorInput : ""
                              }`}
                            />
                            {errors[index]?.state && (
                              <span className={styles.errorMessage}>
                                {errors[index].state}
                              </span>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            <input
                              type="text"
                              name="city"
                              placeholder="City"
                              value={emp.city}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.city ? styles.errorInput : ""
                              }`}
                            />
                            {errors[index]?.city && (
                              <span className={styles.errorMessage}>
                                {errors[index].city}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Employment Dates */}
                        <div className={styles.formRow}>
                          <div className={styles.inputWrapper}>
                            <label className={styles.inputLabel}>
                              Start Date
                            </label>

                            <input
                              type="date"
                              name="start_date"
                              placeholder="Start Date"
                              value={emp.start_date}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.start_date
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.start_date && (
                              <span className={styles.errorMessage}>
                                {errors[index].start_date}
                              </span>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            <label className={styles.inputLabel}>
                              End Date
                            </label>

                            <input
                              type="date"
                              name="end_date"
                              placeholder="End Date"
                              value={emp.end_date}
                              disabled={emp.is_current}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.end_date ? styles.errorInput : ""
                              }`}
                            />
                            {errors[index]?.end_date && (
                              <span className={styles.errorMessage}>
                                {errors[index].end_date}
                              </span>
                            )}
                            <div className={styles.checkboxField}>
                              <input
                                type="checkbox"
                                id={`is_current-${index}`}
                                name="is_current"
                                checked={emp.is_current}
                                onChange={(e) => handleChange(e, index)}
                              />
                              <label
                                className={styles.checkboxLabel}
                                htmlFor={`is_current-${index}`}
                              >
                                Currently Working here
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* Job Details */}
                        <div className={styles.formRow}>
                          <div className={styles.inputWrapper}>
                            <input
                              type="text"
                              name="job_title"
                              placeholder="Job Title"
                              value={emp.job_title}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.job_title
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.job_title && (
                              <span className={styles.errorMessage}>
                                {errors[index].job_title}
                              </span>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            <CustomDropdown
                              name="job_type"
                              value={emp.job_type}
                              onChange={(e) => handleChange(e, index)}
                              options={EMPLOYMENT_TYPES}
                              placeholder="Select Job Type"
                              error={errors[index]?.job_type}
                              // label="Job Type"
                              required
                            />
                          </div>
                        </div>

                        {/* Additional Details */}
                        <div className={styles.formRow}>
                          <div className={styles.inputWrapper}>
                            <input
                              type="number"
                              name="annual_salary"
                              placeholder="Annual Salary"
                              value={emp.annual_salary}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.annual_salary
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.annual_salary && (
                              <span className={styles.errorMessage}>
                                {errors[index].annual_salary}
                              </span>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            <input
                              type="number"
                              name="working_hours_per_week"
                              placeholder="Working Hours per Week"
                              value={emp.working_hours_per_week}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.working_hours_per_week
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.working_hours_per_week && (
                              <span className={styles.errorMessage}>
                                {errors[index].working_hours_per_week}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Job Summary */}
                        <div className={styles.textAreaRow}>
                          <div className={styles.inputWrapper}>
                            <textarea
                              name="job_summary"
                              placeholder="Job Summary"
                              value={emp.job_summary}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.textArea} ${
                                errors[index]?.job_summary
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.job_summary && (
                              <span className={styles.errorMessage}>
                                {errors[index].job_summary}
                              </span>
                            )}
                          </div>

                          {/* Required Skills */}
                          <div className={styles.inputWrapper}>
                            <textarea
                              name="required_skills"
                              placeholder="Required Skills"
                              value={emp.required_skills}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.textArea} ${
                                errors[index]?.required_skills
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.required_skills && (
                              <span className={styles.errorMessage}>
                                {errors[index].required_skills}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className={styles.formActions}>
                  <button
                    type="button"
                    className={styles.secondaryButton}
                    onClick={addNewEmployment}
                  >
                    Add Another Employment Info
                  </button>
                  <div className={styles.navButtons}>
                    <Button
                      variant="secondary"
                      onClick={usePreviousNavigation()}
                    >
                      Previous
                    </Button>
                    <Button variant="secondary" onClick={handleSave}>
                      Save
                    </Button>
                    <Button variant="primary" onClick={handleContinue}>
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmploymentInfoPage;
