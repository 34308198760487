import { protectedApi } from "../../services/api";
const POLL_INTERVAL = 300000; // 10 seconds
const MAX_TIMEOUT = 1500000; // 15 minutes

class TaskManager {
  constructor() {
    this.activeTasks = new Map();
    this.taskCallbacks = new Map();
  }

  startTask(taskId, type, onSuccess, onError) {
    const taskInfo = {
      type,
      startTime: Date.now(),
      pollInterval: null,
      status: "running",
    };

    this.activeTasks.set(taskId, taskInfo);
    this.taskCallbacks.set(taskId, { onSuccess, onError });

    this.pollTask(taskId);
  }

  async pollTask(taskId) {
    const taskInfo = this.activeTasks.get(taskId);
    const callbacks = this.taskCallbacks.get(taskId);

    if (!taskInfo || !callbacks) return;

    const pollInterval = setInterval(async () => {
      try {
        const response = await protectedApi.get(`/api/task-status/${taskId}/`);

        if (response.data.status === "SUCCESS") {
          clearInterval(pollInterval);
          this.activeTasks.delete(taskId);
          this.taskCallbacks.delete(taskId);
          callbacks.onSuccess(response.data.result);
        } else if (response.data.status === "FAILURE") {
          throw new Error(response.data.error || "Task failed");
        }

        // Check timeout
        if (Date.now() - taskInfo.startTime > MAX_TIMEOUT) {
          throw new Error("Task timed out");
        }
      } catch (error) {
        clearInterval(pollInterval);
        this.activeTasks.delete(taskId);
        this.taskCallbacks.delete(taskId);
        callbacks.onError(error);
      }
    }, POLL_INTERVAL);

    taskInfo.pollInterval = pollInterval;
  }

  cancelTask(taskId) {
    const taskInfo = this.activeTasks.get(taskId);
    if (taskInfo?.pollInterval) {
      clearInterval(taskInfo.pollInterval);
    }
    this.activeTasks.delete(taskId);
    this.taskCallbacks.delete(taskId);
  }

  getActiveTasks() {
    return Array.from(this.activeTasks.entries());
  }
}

export const taskManager = new TaskManager();
