import React, { useState, useEffect, useRef, useContext } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../common/Sidebar.js";
import Header2 from "../common/Header2.js";
import styles from "./recommender_config.module.css";
import Button from "../common/Button.js";
import { protectedApi } from "../../services/api.js";
import { ENDORSEMENT_GOALS } from "../../constants/dropdownConsts.js";
import { productMap, checkUsageLimits } from "../../services/crudHelpers.js";
import { useAlert } from "../common/AlertContext.js";
import { useLoading } from "../common/LoadingContext.js";
import { ProgressBarContext } from "../dashboard/ProgressBar.js";

const COUNTRY_CODES = [
  { code: "+1", country: "USA/Canada", iso: "US" },
  { code: "+44", country: "UK", iso: "GB" },
  { code: "+61", country: "Australia", iso: "AU" },
  { code: "+64", country: "New Zealand", iso: "NZ" },
  { code: "+86", country: "China", iso: "CN" },
  { code: "+81", country: "Japan", iso: "JP" },
  { code: "+82", country: "South Korea", iso: "KR" },
  { code: "+65", country: "Singapore", iso: "SG" },
  { code: "+60", country: "Malaysia", iso: "MY" },
  { code: "+91", country: "India", iso: "IN" },
  // Add more country codes as needed
];

const CustomSelect = ({ value, onChange, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (code) => {
    onChange({ target: { name: "countryCode", value: code } });
    setIsOpen(false);
  };

  const selectedCountry = options.find((c) => c.code === value);
  return (
    <div className={styles.customSelect} ref={dropdownRef}>
      <div className={styles.selectTrigger} onClick={() => setIsOpen(!isOpen)}>
        <img
          src={`https://flagsapi.com/${selectedCountry?.iso}/flat/48.png`}
          alt="Selected country flag"
          className={styles.flagIcon}
        />
        <span>{value}</span>
        <span className={styles.arrow}>▼</span>
      </div>
      {isOpen && (
        <div className={styles.optionsList}>
          {options.map((country) => (
            <div
              key={country.code}
              className={styles.option}
              onClick={() => handleSelect(country.code)}
            >
              <img
                src={`https://flagsapi.com/${country.iso}/flat/24.png`}
                alt={country.country}
                className={styles.flagIcon}
              />
              <span>{country.code}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CATEGORIES = [
  { value: "academic", label: "Academic" },
  { value: "work", label: "Work" },
];

function RecommenderConfig() {
  const { handleComplete } = useContext(ProgressBarContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const refereeId = searchParams.get("ref");
  const [activeTab, setActiveTab] = useState("profile");
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    countryCode: "+1",
    phone: "",
    pronoun: "",
    gender: "",
    linkedin: "",
    category: "",
    title: "",
    organization: "",
    department: "",
    resume: null,
    frequency: "",
    years_of_known: "",
    relationship: "",
    comments: "",
  });
  const { showAlert } = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [selectedEndorsedItems, setSelectedEndorsedItems] = useState([]);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [selectedGoals, setSelectedGoals] = useState([]);

  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedLetterUrl, setGeneratedLetterUrl] = useState(null);
  const [generationError, setGenerationError] = useState(null);

  // Add new state for proposed endeavors
  const [proposedEndeavors, setProposedEndeavors] = useState([]);
  const [selectedEndeavors, setSelectedEndeavors] = useState([]);

  // Add new state for loading proposed endeavors
  const [isLoadingEndeavors, setIsLoadingEndeavors] = useState(true);

  // Add this with other state declarations
  const [remainingUsage, setRemainingUsage] = useState(null);

  // Add new state to track if data has been loaded
  const [dataLoaded, setDataLoaded] = useState({
    endorsedItems: false,
    proposedEndeavors: false,
  });

  // Add this state at the top of your component with other state declarations
  const [totalPurchased, setTotalPurchased] = useState(0);
  // Add new state for tracking task ID
  const [currentTaskId, setCurrentTaskId] = useState(null);

  // Add new state for tracking all generated letters
  const [generatedLetters, setGeneratedLetters] = useState([]);

  // Add function to check letter generation status
  const checkLetterStatus = async (taskId) => {
    try {
      const statusResponse = await protectedApi.get(
        `/api/task-status/${taskId}/`
      );
      console.log("Status Response:", statusResponse.data);

      if (statusResponse.data.status === "SUCCESS") {
        const recLetterId = statusResponse.data.result.recLetter_id;
        const urlResponse = await protectedApi.get(
          `/api/product/rec/letter/${refereeId}/url/${recLetterId}/`
        );

        if (urlResponse.status === 200 && urlResponse.data.rec_letter_url) {
          setGeneratedLetterUrl(urlResponse.data.rec_letter_url);
          setRemainingUsage((prev) => Math.max(0, prev - 1));
          showAlert("Letter generation successful");
          setIsGenerating(false);
          setCurrentTaskId(null);
        }
      } else if (statusResponse.data.status === "FAILURE") {
        const errorMessage =
          statusResponse.data.error || "Letter generation failed";
        setGenerationError(errorMessage);
        showAlert(errorMessage);
        setIsGenerating(false);
        setCurrentTaskId(null);
      }
      // If still in progress, keep current state
    } catch (error) {
      console.error("Error checking letter status:", error);
      if (error.response?.status === 404) {
        // Task not found, reset states
        setIsGenerating(false);
        setCurrentTaskId(null);
      }
    }
  };

  // Modify fetchExistingLetter to fetch all letters
  const fetchExistingLetter = async () => {
    try {
      showLoading("Fetching existing letters...");
      const response = await protectedApi.get(
        `/api/product/rec/letter/${refereeId}/`
      );
      if (response.status === 200) {
        const letterData = response.data;

        // If there's an active task, check its status
        if (letterData.current_task_id) {
          setCurrentTaskId(letterData.current_task_id);
          setIsGenerating(true);
          await checkLetterStatus(letterData.current_task_id);
        }

        // Store all generated letters
        if (letterData.letters) {
          setGeneratedLetters(letterData.letters);
        }
      }
    } catch (error) {
      console.error("Error fetching existing letters:", error);
      showAlert("Error fetching existing letters");
    } finally {
      hideLoading();
    }
  };
  // Add polling effect when generation is in progress
  useEffect(() => {
    let pollInterval;

    if (isGenerating && currentTaskId) {
      pollInterval = setInterval(() => {
        checkLetterStatus(currentTaskId);
      }, 2000);

      // Set timeout to prevent infinite polling
      setTimeout(() => {
        if (pollInterval) {
          clearInterval(pollInterval);
          setIsGenerating(false);
          setCurrentTaskId(null);
          setGenerationError("Generation timed out. Please try again.");
          showAlert("Letter generation timed out. Please try again.");
        }
      }, 300000); // 5 minutes timeout
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [isGenerating, currentTaskId]);
  // Add this helper function near the top of the file
  const downloadFile = async (url, filename) => {
    const MAX_RETRIES = 3;
    const TIMEOUT = 30000; // 30 seconds
    let attempt = 0;

    while (attempt < MAX_RETRIES) {
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);

        const response = await fetch(url, {
          signal: controller.signal,
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const objectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = filename || "recommendation_letter.docx";
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectUrl);
        return;
      } catch (error) {
        attempt++;
        console.error(`Download attempt ${attempt} failed:`, error);

        if (attempt === MAX_RETRIES) {
          throw new Error(
            `Failed to download file after ${MAX_RETRIES} attempts`
          );
        }

        // Wait before retrying (exponential backoff)
        await new Promise((resolve) =>
          setTimeout(resolve, Math.pow(2, attempt) * 1000)
        );
      }
    }
  };

  // Modify fetchAllItems to check if data is already loaded
  const fetchAllItems = async () => {
    // If items are already loaded, skip fetching
    if (dataLoaded.endorsedItems) {
      console.log("Endorsed items already loaded, skipping fetch");
      return;
    }

    setIsLoadingItems(true);
    try {
      if (!refereeId) {
        console.warn("No referee ID found");
        showAlert("No referee found, please create a referee first");
        navigate("/recommender");
        return;
      }

      const applicationId = localStorage.getItem("applicationId");
      const headers = applicationId
        ? { "X-Application-ID": applicationId }
        : {};
      console.log("applicationId", applicationId);

      const endpoints = {
        futurePlans: {
          url: "/api/futureplan/",
          mapData: (item) => ({
            ...item,
            title: item.plan_title || item.title || "Untitled Future Plan",
            description:
              item.area_of_focus ||
              item.current_progress ||
              item.description ||
              "No description available",
          }),
        },
        academicHistory: {
          url: "/api/academichistory/",
          mapData: (item) => ({
            ...item,
            title: item.school_name || "Untitled Academic History",
            description:
              `${item.major} ${item.degree}` ||
              item.description ||
              "No description available",
          }),
        },
        academicProjects: {
          url: "/api/academicprojects/",
          mapData: (item) => ({
            ...item,
            title:
              item.title || item.project_name || "Untitled Academic Project",
            description: item.background || "No description available",
            goal: item.goal || "No goal available",
          }),
        },
        professionalHistory: {
          url: "/api/professionalhistory/",
          mapData: (item) => ({
            ...item,
            title:
              item.organization ||
              item.job_title ||
              "Untitled Professional History",
            description: `${item.job_type} (${
              item.start_date || "No start date"
            } - ${item.end_date || "Present"})`,
          }),
        },
        professionalProjects: {
          url: "/api/professionalprojects/",
          mapData: (item) => ({
            ...item,
            title:
              item.job_title ||
              item.organization ||
              "Untitled Professional Project",
            description: item.background || "No description available",
          }),
        },
        evidence: {
          url: "/api/evidence/",
          mapData: (item) => ({
            ...item,
            title:
              item.name ||
              item.domain ||
              item.issuing_organization ||
              "Untitled Evidence",
            description:
              item.description ||
              item.brief_introduction ||
              "No description available",
          }),
        },
      };

      const results = {};

      // Process each endpoint sequentially to better handle individual failures
      for (const [key, endpoint] of Object.entries(endpoints)) {
        try {
          const response = await protectedApi.get(endpoint.url, { headers });
          const items = response.data.results || response.data;

          // Map the data using the endpoint-specific mapping function
          results[key] = items.map((item) => ({
            ...endpoint.mapData(item),
            category: key,
            id: item.id,
          }));
        } catch (error) {
          console.error(`Error fetching ${key}:`, error);
          results[key] = []; // Set empty array for failed endpoint
          setErrors((prev) => ({
            ...prev,
            [key]: `Failed to load ${key}. ${
              error.response?.data?.detail || error.message
            }`,
          }));
        }
      }

      // Get existing configurations for this referee
      try {
        const configResponse = await protectedApi.get(
          `/api/product/rec/conf/${refereeId}/`
        );
        console.log("Config Response:", configResponse.data);

        // Extract the configured item IDs from the response
        const configuredItemIds = configResponse.data.map(
          (config) => config.item_id
        );

        // Mark items as selected if they exist in the configuration
        Object.keys(results).forEach((key) => {
          results[key] = results[key].map((item) => ({
            ...item,
            isChecked: configuredItemIds.includes(item.id),
          }));
        });
        console.log("results", results);
        setEndorsedItems((prev) => ({
          ...prev, // Preserve existing state including proposedEndeavor
          ...results, // Add new results for other categories
        }));
        console.log("Loaded items with preserved proposedEndeavor:", results);
      } catch (error) {
        console.error("Error fetching configurations:", error);
        // If there's an error, mark all items as unselected
        Object.keys(results).forEach((key) => {
          results[key] = results[key].map((item) => ({
            ...item,
            isChecked: false,
          }));
        });
        setErrors((prev) => ({
          ...prev,
          configs: "Failed to load saved configurations",
        }));
        showAlert("Error fetching configurations");
      } finally {
        hideLoading();
      }

      // Mark endorsed items as loaded after successful fetch
      setDataLoaded((prev) => ({ ...prev, endorsedItems: true }));
      console.log("Loaded items:", results);
    } catch (error) {
      console.error("Error in fetchAllItems:", error);
      setErrors((prev) => ({
        ...prev,
        general: "Failed to load items. Please try again.",
      }));
    } finally {
      setIsLoadingItems(false);
    }
  };

  // Modify fetchProposedEndeavors to check if data is already loaded
  const fetchProposedEndeavors = async () => {
    // If proposed endeavors are already loaded, skip fetching
    if (dataLoaded.proposedEndeavors) {
      console.log("Proposed endeavors already loaded, skipping fetch");
      return;
    }

    setIsLoadingEndeavors(true);
    try {
      const response = await protectedApi.get(
        "/api/product/proposed-endeavor/"
      );
      const refereeId = localStorage.getItem("refereeId");

      if (response.status === 200) {
        // Get selected endeavors (available for endorsement)
        const selectedEndeavors = (response.data.results || response.data)
          .filter((endeavor) => endeavor.is_selected === true)
          .map((endeavor) => ({
            id: endeavor.id,
            title: endeavor.title,
            description:
              endeavor.exp_applicant_qualifications ||
              endeavor.exp_nation_impt_impact ||
              endeavor.description,
            isChecked: false, // Default to unchecked
          }));

        // If we have a refereeId, check which ones are endorsed
        if (refereeId) {
          try {
            const configResponse = await protectedApi.get(
              `/api/product/rec/conf/${refereeId}/`
            );

            const endorsedIds = configResponse.data
              .filter((item) => item.category === "proposedendeavor")
              .map((item) => item.item_id);
            // console.log("endorsedIds:", endorsedIds);
            // Update isChecked based on endorsement config
            selectedEndeavors.forEach((endeavor) => {
              endeavor.isChecked = endorsedIds.includes(endeavor.id);
            });
          } catch (error) {
            console.error("Error fetching endorsement config:", error);
            showAlert("Error fetching endorsement config");
          }
        }

        setEndorsedItems((prev) => ({
          ...prev,
          proposedEndeavor: selectedEndeavors,
        }));
      }
    } catch (error) {
      console.error("Error fetching proposed endeavors:", error);
      setEndorsedItems((prev) => ({
        ...prev,
        proposedEndeavor: [],
      }));
    } finally {
      setIsLoadingEndeavors(false);
      hideLoading();
    }

    // Mark proposed endeavors as loaded after successful fetch
    setDataLoaded((prev) => ({ ...prev, proposedEndeavors: true }));
  };

  const [endorsedItems, setEndorsedItems] = useState({
    proposedEndeavor: [],
    futurePlans: [],
    academicHistory: [],
    academicProjects: [],
    professionalHistory: [],
    professionalProjects: [],
    evidence: [],
  });

  // Add this handler
  const handleGoalSelection = (goalId) => {
    setSelectedGoals((prev) => {
      if (prev.includes(goalId)) {
        return prev.filter((id) => id !== goalId);
      } else {
        return [...prev, goalId];
      }
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      switch (activeTab) {
        case "profile":
          const isValid = validateForm();
          if (!isValid) {
            showAlert("Please fill in all required fields correctly");
            return;
          }
          // Save profile data
          showAlert("Profile saved successfully!");
          break;

        case "letters":
          // Save letter generation progress
          showAlert("Letter generation progress saved!");
          break;

        case "final":
          // Save final letter draft
          showAlert("Letter draft saved!");
          break;
      }
    } catch (error) {
      console.error("Error saving:", error);
      showAlert("Failed to save. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      switch (activeTab) {
        case "profile":
          const isValid = validateForm();
          if (!isValid) {
            const errorMessage = Object.entries(errors)
              .map(([field, message]) => `${field}: ${message}`)
              .join("\n");
            showAlert(errorMessage);
            // alert("Please fill in all required fields correctly");
            setIsLoading(false);
            return;
          }

          // Prepare form data for submission
          const formDataObj = new FormData();

          // Format years_of_known as decimal
          const yearsKnown = parseFloat(formData.years_of_known).toFixed(1);

          Object.entries({
            ...formData,
            years_of_known: yearsKnown,
            department: formData.department || "#", // Provide default value
          }).forEach(([key, value]) => {
            formDataObj.append(key, value || "");
          });

          // Add resume file if it exists
          if (formData.resume instanceof File) {
            formDataObj.append("resume", formData.resume);
          }
          console.log("formDataObj:", formDataObj);
          console.log("POST to /api/referee/");

          try {
            const response = await protectedApi.post(
              "/api/referee/",
              formDataObj,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
              }
            );

            if (response.status === 201 || response.status === 200) {
              console.log("Response:", response.data[0].id);
              localStorage.setItem("refereeId", response.data[0].id);
              setActiveTab("letters");
              setErrors({});
            }
          } catch (error) {
            console.error("API Error:", error);
            if (error.response?.data) {
              const backendErrors = error.response.data;
              const newErrors = {};

              Object.entries(backendErrors).forEach(([field, messages]) => {
                newErrors[field] = Array.isArray(messages)
                  ? messages[0]
                  : messages;
              });

              setErrors(newErrors);
              throw new Error("Please correct the form errors");
            } else {
              setErrors({
                general: "An unexpected error occurred. Please try again.",
              });
              throw new Error("Network error occurred");
            }
          }
          break;

        case "letters":
          if (selectedEndorsedItems.length === 0) {
            showAlert("Please select at least one item to endorse");
            setIsLoading(false);
            return;
          }
          setActiveTab("final");
          break;

        case "final":
          if (!uploadedFile) {
            showAlert("Please upload a signed letter before submitting");
            setIsLoading(false);
            return;
          }
          navigate("/petition-letter");

          // try {
          //   const refereeId = localStorage.getItem("refereeId");
          //   if (!refereeId) {
          //     showAlert("Recommender information not found");
          //     navigate("/recommender");
          //     return;
          //   }

          //   const letterFormData = new FormData();
          //   letterFormData.append("uploaded_signed_file", uploadedFile);

          //   const letterResponse = await protectedApi.post(
          //     `/api/rec-letter/${refereeId}/upload-signed/`,
          //     letterFormData,
          //     {
          //       headers: {
          //         "Content-Type": "multipart/form-data",
          //       },
          //       withCredentials: true,
          //     }
          //   );

          //   if (
          //     letterResponse.status === 200 ||
          //     letterResponse.status === 201
          //   ) {
          //     showAlert("Signed letter submitted successfully!");
          //     navigate("/dashboard");
          //   } else {
          //     throw new Error("Failed to submit signed letter");
          //   }
          // } catch (error) {
          //   console.error("Error submitting signed letter:", error);
          //   showAlert(
          //     error.response?.data?.message || "Failed to submit signed letter"
          //   );
          //   setIsLoading(false);
          // }
          break;

        default:
          console.error("Unknown tab:", activeTab);
      }
    } catch (error) {
      console.error("Error:", error);
      // Don't clear existing errors if they're validation errors
      if (!error.message?.includes("Please correct the errors")) {
        setErrors({});
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrevious = () => {
    switch (activeTab) {
      case "profile":
        navigate("/recommender");
        break;
      case "letters":
        setActiveTab("profile");
        break;
      case "final":
        setActiveTab("letters");
        break;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Update title when name changes
    if (name === "name") {
      document.title = `Recommender - ${value}`;
    }

    // Clear error for the field being changed
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = {
      first_name: "First name",
      last_name: "Last name",
      email: "Email",
      phone: "Phone number",
      pronoun: "Pronoun",
      category: "Category",
      title: "Title",
      organization: "Organization",
      frequency: "Contact frequency",
      years_of_known: "Years known",
      relationship: "Relationship",
    };

    Object.entries(requiredFields).forEach(([field, label]) => {
      if (!formData[field] || String(formData[field]).trim() === "") {
        newErrors[field] = `${label} is required`;
      }
    });

    // Email validation
    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    // Phone validation
    if (formData.phone && !/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Please enter a valid 10-digit phone number";
    }

    // Years known validation
    if (formData.years_of_known) {
      const years = parseFloat(formData.years_of_known);
      if (isNaN(years) || years <= 0) {
        newErrors.years_of_known = "Please enter a valid number of years";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e, action = "save") => {
    e.preventDefault();
    const isValid = validateForm();

    if (!isValid) {
      console.log("Errors:", errors);
      showAlert("Please fill in all required fields correctly");
      return;
    }

    // Handle form submission logic here
    if (action === "continue") {
      navigate("/recommender/letters");
    } else {
      showAlert("Profile saved successfully!");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // todo: check usage, get usage, get usage history for every generation item
  // Add this function to handle letter generation
  const handleGenerateLetter = async () => {
    if (isGenerateButtonDisabled()) {
      let missingItems = [];
      const hasSelectedItems = Object.entries(endorsedItems)
        .filter(([category]) => category !== "proposedEndeavor")
        .some(
          ([_, items]) =>
            Array.isArray(items) && items.some((item) => item.isChecked)
        );

      if (!hasSelectedItems) missingItems.push("Endorsed Items");

      const hasSelectedEndeavors =
        Array.isArray(endorsedItems.proposedEndeavor) &&
        endorsedItems.proposedEndeavor.some((endeavor) => endeavor.isChecked);
      if (!hasSelectedEndeavors) missingItems.push("Proposed Endeavors");
      if (selectedGoals.length === 0) missingItems.push("Endorsed Goals");

      if (missingItems.length > 0) {
        showAlert(
          `Please select at least one of each required item:\n ${missingItems.join(
            "\n "
          )}`
        );
        return;
      }
      if (isGenerating) {
        handleComplete("recommender");
        showAlert("Letter is generating, please wait few minutes");
        return;
      }
    }

    try {
      // Set generating state at the start
      setIsGenerating(true);
      setGenerationError(null);

      // Check usage limits
      const usageCheck = await checkUsageLimits(
        "Recommendation Letter",
        showAlert
      );
      if (!usageCheck.success) {
        if (usageCheck.action === "redirect") {
          navigate("/plans");
        }
        showAlert(usageCheck.message);
        setIsGenerating(false);
        return;
      }

      setRemainingUsage(usageCheck.remaining_usage);
      setTotalPurchased(usageCheck.totalBalance);

      const refereeId = localStorage.getItem("refereeId");
      if (!refereeId) {
        showAlert("Recommender is not found");
        navigate("/recommender");
        setIsGenerating(false);
        return;
      }

      // Initial generation request
      const generateResponse = await protectedApi.post(
        `/api/product/rec/letter/${refereeId}/`
      );

      if (generateResponse.status === 201 || generateResponse.status === 200) {
        const taskId = generateResponse.data.task_id;
        setCurrentTaskId(taskId);
        // Initial status check
        await checkLetterStatus(taskId);
      }
    } catch (error) {
      console.error("Error initiating letter generation:", error);
      setGenerationError(error.message || "Failed to start letter generation");
      showAlert(error.message || "Failed to start letter generation");
      setIsGenerating(false);
      setCurrentTaskId(null);
    }
  };

  // if (generateResponse.status === 201 || generateResponse.status === 200) {
  //   const letterData = generateResponse.data;
  //   const letterID = letterData.id;
  //   const taskId = generateResponse.data.task_id;

  //   // Poll for letter URL
  //   let attempts = 36;
  //   const pollInterval = 5000;

  //   const pollForLetter = async () => {
  //     while (attempts > 0) {
  //       try {
  //         const statusResponse = await protectedApi.get(
  //           `/api/task-status/${taskId}/`
  //         );

  // const urlResponse = await protectedApi
  //   .get
  // `/api/product/rec/letter/${refereeId}/url/${letterID}/`
  // ();

  //             if (urlResponse.status === 200 && urlResponse.data.url) {
  //               setGeneratedLetterUrl(urlResponse.data.url);
  //               setIsGenerating(false);
  //               showAlert("Letter generation successful");
  //               setRemainingUsage((prev) => Math.max(0, prev - 1));
  //               return;
  //             }
  //           } catch (error) {
  //             console.error("Polling attempt failed:", error);
  //           }
  //           attempts--;
  //           await new Promise((resolve) => setTimeout(resolve, pollInterval));
  //         }
  //         throw new Error("Letter generation timed out");
  //       };

  //       await pollForLetter();
  //     } catch (error) {
  //       console.error("Error generating letter:", error);
  //       setGenerationError(error.message || "Failed to generate letter");
  //       setIsGenerating(false);
  //       showAlert(error.message || "Failed to generate letter");
  //       setRemainingUsage((prev) => Math.min(prev + 1, totalPurchased));
  //     }
  //   };

  // Add letter generation form content

  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
      // Here you would typically handle the file upload to your backend
    }
  };

  const renderFinalLetter = () => {
    return (
      <div className={styles.finalLetterContainer}>
        <div className={styles.uploadSection}>
          <p className={styles.uploadText}>
            Upload a signed version to finalize your recommendation letter
          </p>
          <label className={styles.uploadButton}>
            <span className={styles.uploadIcon}>↑</span>
            Upload Signed Letter
            <input
              type="file"
              onChange={handleFileUpload}
              accept=".pdf,.doc,.docx"
              style={{ display: "none" }}
            />
          </label>
        </div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className={styles.buttonContainer}>
        <Button
          variant="secondary"
          onClick={handlePrevious}
          disabled={isLoading}
        >
          Previous
        </Button>
        <Button variant="secondary" onClick={handleSave} disabled={isLoading}>
          Save
        </Button>
        <Button variant="primary" onClick={handleContinue} disabled={isLoading}>
          {isLoading
            ? "Processing..."
            : activeTab === "final"
            ? "Submit Signed Letter"
            : "Continue"}
        </Button>
      </div>
    );
  };

  // Phone input
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-digits
    if (value.length <= 10) {
      // Limit to 10 digits
      handleChange({
        target: {
          name: "phone",
          value: value,
        },
      });
    }
  };

  // LinkedIn input
  const handleLinkedInChange = (e) => {
    let value = e.target.value;

    // Only add https:// if the user is entering a linkedin.com URL
    if (value && value.includes("linkedin.com") && !value.startsWith("http")) {
      value = `https://${value}`;
    }

    // Remove any spaces from the URL
    value = value.trim();

    handleChange({
      target: {
        name: "linkedin",
        value: value,
      },
    });
  };

  // Add new function to fetch referee data
  const fetchRefereeData = async (refereeId) => {
    try {
      showLoading("Fetching referee data...");
      const response = await protectedApi.get(`/api/referee/${refereeId}/`);
      if (response.status === 200) {
        // Update form data with existing referee information
        setFormData((prevData) => ({
          ...prevData,
          ...response.data,
          // Ensure phone number formatting is maintained
          phone: response.data.phone?.replace(/\D/g, ""),
          // Handle any nested or computed fields
          countryCode: response.data.country_code || "+1",
        }));
        console.log("rl profile", response);
      }
    } catch (error) {
      console.error("Error fetching referee data:", error);
      showAlert("Error fetching referee data, please reload the page");
    } finally {
      hideLoading();
    }
  };

  // Add new function to fetch final letter data
  const fetchFinalSignedLetterData = async (refereeId) => {
    try {
      const response = await protectedApi.get(`/api/RecLetter/`);
      if (response.status === 200 && response.data.signed_letter_url) {
        setUploadedFile(response.data.signed_letter_url);
      }
    } catch (error) {
      console.error("Error fetching final letter data:", error);
    }
  };

  // Modify the existing tab change useEffect
  useEffect(() => {
    const currentRefereeId = refereeId || localStorage.getItem("refereeId");

    if (currentRefereeId) {
      switch (activeTab) {
        case "profile":
          fetchRefereeData(refereeId);
          break;
        case "letters":
          // Only fetch if data hasn't been loaded
          if (!dataLoaded.endorsedItems) {
            fetchAllItems();
          }
          if (!dataLoaded.proposedEndeavors) {
            fetchProposedEndeavors();
          }
          fetchExistingLetter();
          break;
        case "final":
          fetchFinalSignedLetterData(refereeId);
          break;
        default:
          console.log("Invalid tab");
      }
    }
  }, [activeTab, refereeId, dataLoaded]);

  // Add a reset function for when we need to force a refresh
  const resetData = () => {
    setDataLoaded({
      endorsedItems: false,
      proposedEndeavors: false,
    });
  };

  // Add this function to check if selections are valid
  const isGenerateButtonDisabled = () => {
    // Check if any items are selected
    const hasSelectedItems = Object.values(endorsedItems)
      .flat()
      .some((item) => item.isChecked);

    // Check if any proposed endeavors are selected
    const hasSelectedEndeavors = endorsedItems.proposedEndeavor?.some(
      (endeavor) => endeavor.isChecked
    );

    // Check if any goals are selected
    const hasSelectedGoals = selectedGoals.length > 0;
    console.log("Selection status:", {
      hasSelectedItems,
      hasSelectedEndeavors,
      hasSelectedGoals,
      endorsedItems,
      selectedGoals,
      isGenerating,
    });
    return (
      isGenerating ||
      !hasSelectedItems ||
      !hasSelectedEndeavors ||
      !hasSelectedGoals
    );
  };

  // Add this useEffect to monitor endorsedItems changes
  useEffect(() => {
    console.log("Updated endorsed items:", endorsedItems);
  }, [endorsedItems]);

  // Modify the renderItemSection function to better handle the data
  const renderItemSection = (category, items, title) => {
    if (!items || items.length === 0) return null;

    return (
      <div key={category} className={styles.endorsementSection}>
        <h3>{title}</h3>
        <div className={styles.itemsList}>
          {items.map((item) => (
            <div key={`${category}-${item.id}`} className={styles.goalCheckbox}>
              <input
                type="checkbox"
                id={`${category}-${item.id}`}
                checked={item.isChecked}
                onChange={() => handleItemSelection(category, item.id)}
              />
              <label htmlFor={`${category}-${item.id}`}>
                <div className={styles.itemTitle}>{item.title}</div>
                <div className={styles.itemDescription}>{item.description}</div>
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Update handleItemSelection to properly handle the selection
  const handleItemSelection = async (category, itemId) => {
    const refereeId = localStorage.getItem("refereeId");
    if (!refereeId) {
      console.error("No referee ID found");
      return;
    }

    try {
      const currentItem = endorsedItems[category].find(
        (item) => item.id === itemId
      );

      if (!currentItem) {
        console.error("Item not found:", category, itemId);
        return;
      }

      const isCurrentlySelected = currentItem.isChecked;

      // Optimistically update UI
      setEndorsedItems((prev) => ({
        ...prev,
        [category]: prev[category].map((item) =>
          item.id === itemId
            ? { ...item, isChecked: !isCurrentlySelected }
            : item
        ),
      }));

      if (isCurrentlySelected) {
        // Delete configuration
        const response = await protectedApi.delete(
          `/api/product/rec/conf/${refereeId}/`,
          {
            data: {
              item_category: category.toLowerCase(),
              item_id: itemId,
            },
          }
        );
        if (response.status === 204) {
          showAlert("Item removed from configuration");
        } else {
          showAlert("Failed to remove item from configuration");
          setErrors((prev) => ({
            ...prev,
            configs: "Failed to remove item from configuration",
            isChecked: true,
          }));
        }
      } else {
        // Add configuration
        const response = await protectedApi.post(
          `/api/product/rec/conf/${refereeId}/`,
          {
            item_category: category.toLowerCase(),
            item_id: itemId,
          }
        );
        if (response.status === 201) {
          showAlert("Item added to configuration");
        } else {
          showAlert("Failed to add item to configuration");
          setErrors((prev) => ({
            ...prev,
            configs: "Failed to add item to configuration",
            isChecked: false,
          }));
        }
      }
    } catch (error) {
      console.error("Error updating item selection:", error);
      // Revert optimistic update on error
      setEndorsedItems((prev) => ({
        ...prev,
        [category]: prev[category].map((item) =>
          item.id === itemId ? { ...item, isChecked: !item.isChecked } : item
        ),
      }));
      setErrors((prev) => ({
        ...prev,
        selection: "Failed to update selection. Please try again.",
      }));
    }
  };

  // useEffect(() => {
  //   fetchAllItems();
  // }, []);

  // Add this inside your renderLetterGeneration function
  const renderAllSections = () => (
    <div className={styles.endorsementSection}>
      <div className={styles.sectionHeader}>
        <h3>Please select the items you would endorse</h3>
        <div className={styles.helperText}>Select items for endorsement</div>
      </div>
      <div className={styles.letterSection}>
        {isLoadingItems ? (
          <div className={styles.loadingContainer}>
            <div className={styles.loadingSpinner}></div>
            <span>Loading Items...</span>
          </div>
        ) : (
          Object.entries(endorsedItems)
            .filter(([category]) => category !== "proposedEndeavor")
            .map(([category, categoryItems]) =>
              renderItemSection(
                category,
                categoryItems,
                category
                  .replace(/([A-Z])/g, " $1")
                  .trim()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              )
            )
        )}
      </div>
    </div>
  );

  // Update renderPreviewSection to show list of letters when they exist
  const renderPreviewSection = () => (
    <div className={styles.letterPreviewContent}>
      {isGenerating ? (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner}></div>
          <div className={styles.loadingMessage}>
            <h4>Generating Your Recommendation Letter</h4>
            <p>This process may take up to 10 minutes.</p>
          </div>
        </div>
      ) : generatedLetters.length > 0 ? (
        <div className={styles.lettersList}>
          <h3>Generated Letters</h3>
          {generatedLetters.map((letter, index) => (
            <div key={letter.id} className={styles.letterItem}>
              <span className={styles.letterTitle}>
                Recommendation Letter {index + 1}
              </span>
              <span className={styles.letterDate}>
                {new Date(letter.created_at).toLocaleDateString()}
              </span>
              <div className={styles.letterActions}>
                <button
                  className={styles.downloadButton}
                  onClick={async () => {
                    try {
                      showAlert("Starting download...");
                      await downloadFile(
                        letter.url,
                        `recommendation_letter_${
                          formData.last_name || "unnamed"
                        }_${index + 1}.docx`
                      );
                      showAlert("Letter downloaded successfully!");
                    } catch (error) {
                      console.error("Download error:", error);
                      showAlert(
                        "Failed to download letter. Please try again or open in new tab."
                      );
                    }
                  }}
                >
                  <span className={styles.downloadIcon}></span>
                  Download
                </button>
                <button
                  className={styles.newTabButton}
                  onClick={() => window.open(letter.url, "_blank")}
                >
                  <span className={styles.newTabIcon}>↗</span>
                  View
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : generatedLetterUrl ? (
        <div className={styles.previewContainer}>
          <div className={styles.pdfViewer}>
            {/* Primary PDF viewer */}
            <embed
              src={`${generatedLetterUrl}#toolbar=1&navpanes=1&scrollbar=1&page=1&view=FitH`}
              type="application/pdf"
              className={styles.letterPreview}
              style={{ width: "100%", height: "600px" }}
            />

            {/* Fallback for browsers that don't support embed */}
            <object
              data={generatedLetterUrl}
              type="application/pdf"
              className={styles.letterPreview}
              style={{ display: "none" }}
              onError={(e) => {
                e.target.style.display = "none";
                const iframe = document.createElement("iframe");
                iframe.src = `https://docs.google.com/viewer?url=${encodeURIComponent(
                  generatedLetterUrl
                )}&embedded=true`;
                iframe.className = styles.letterPreview;
                e.target.parentNode.appendChild(iframe);
              }}
            >
              <iframe
                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                  generatedLetterUrl
                )}&embedded=true`}
                className={styles.letterPreview}
                style={{ width: "100%", height: "600px" }}
                frameBorder="0"
              />
            </object>

            <div className={styles.previewActions}>
              <button
                className={styles.downloadButton}
                onClick={async () => {
                  try {
                    showAlert("Starting download...");
                    await downloadFile(
                      generatedLetterUrl,
                      `recommendation_letter_${
                        formData.last_name || "unnamed"
                      }.docx`
                    );
                    showAlert("Letter downloaded successfully!");
                  } catch (error) {
                    console.error("Download error:", error);
                    showAlert(
                      "Failed to download letter. Please try again or open in new tab."
                    );
                  }
                }}
              >
                <span className={styles.downloadIcon}></span>
                Download Letter
              </button>
              <button
                className={styles.newTabButton}
                onClick={() => window.open(generatedLetterUrl, "_blank")}
              >
                <span className={styles.newTabIcon}>↗</span>
                Open in New Tab
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.emptyPreview}>
          <p>Select items and click Generate Now to create your letter</p>
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>Recommender {formData.name}</h1>

            <div className={styles.formTabs}>
              <button
                className={`${styles.tab} ${
                  activeTab === "profile" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveTab("profile")}
              >
                Profile
              </button>
              <button
                className={`${styles.tab} ${
                  activeTab === "letters" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveTab("letters")}
              >
                Letter Generation
              </button>
              <button
                className={`${styles.tab} ${
                  activeTab === "final" ? styles.activeTab : ""
                }`}
                onClick={() => setActiveTab("final")}
              >
                Final Letter
              </button>
            </div>

            <div className={styles.formContainer}>
              {activeTab === "profile" && (
                <div className={styles.twoColumnLayout}>
                  {/* Left Column - Personal Information */}
                  <div className={styles.formSection}>
                    <h3>Personal Information</h3>

                    <div className={styles.formField}>
                      <select
                        name="pronoun"
                        value={formData.pronoun}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Prefix
                        </option>
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Dr.">Dr.</option>
                      </select>
                      {errors.pronoun && (
                        <div className={styles.errorText}>{errors.pronoun}</div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="text"
                        name="first_name"
                        placeholder="First Name *"
                        value={formData.first_name}
                        onChange={handleChange}
                        className={`${styles.inputField} ${
                          errors.first_name ? styles.errorInput : ""
                        }`}
                      />
                      {errors.first_name && (
                        <div className={styles.errorText}>
                          {errors.first_name}
                        </div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="text"
                        name="middle_name"
                        placeholder="Middle Name"
                        value={formData.middle_name}
                        onChange={handleChange}
                        className={styles.inputField}
                      />
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name *"
                        value={formData.last_name}
                        onChange={handleChange}
                        className={`${styles.inputField} ${
                          errors.last_name ? styles.errorInput : ""
                        }`}
                      />
                      {errors.last_name && (
                        <div className={styles.errorText}>
                          {errors.last_name}
                        </div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address *"
                        value={formData.email}
                        onChange={handleChange}
                        className={`${styles.inputField} ${
                          errors.email ? styles.errorInput : ""
                        }`}
                      />
                      {errors.email && (
                        <div className={styles.errorText}>{errors.email}</div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <div className={styles.phoneInputGroup}>
                        <CustomSelect
                          value={formData.countryCode}
                          onChange={handleChange}
                          options={COUNTRY_CODES}
                        />
                        <input
                          type="tel"
                          name="phone"
                          placeholder="Phone Number *"
                          value={formData.phone}
                          onChange={handlePhoneChange}
                          className={`${styles.phoneInput} ${
                            errors.phone ? styles.errorInput : ""
                          }`}
                          maxLength="10"
                        />
                      </div>
                      {errors.phone && (
                        <div className={styles.errorText}>{errors.phone}</div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className={`${styles.inputField} ${
                          errors.gender ? styles.errorInput : ""
                        }`}
                      >
                        <option value="">Select Gender *</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="non-binary">Non-binary</option>
                        <option value="prefer-not-to-say">
                          Prefer not to say
                        </option>
                      </select>
                      {errors.gender && (
                        <div className={styles.errorText}>{errors.gender}</div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="url"
                        name="linkedin"
                        placeholder="LinkedIn Profile URL (e.g., https://www.linkedin.com/in/username)"
                        value={formData.linkedin}
                        onChange={handleLinkedInChange}
                        className={`${styles.inputField} ${
                          errors.linkedin ? styles.errorInput : ""
                        }`}
                      />
                      {errors.linkedin && (
                        <div className={styles.errorText}>
                          {errors.linkedin}
                        </div>
                      )}
                      <div className={styles.helperText}>
                        Example: https://www.linkedin.com/in/username
                      </div>
                    </div>
                  </div>

                  {/* Middle Column - Professional Information */}
                  <div className={styles.formSection}>
                    <h3>Professional Information</h3>

                    <div className={styles.formField}>
                      <select
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        className={`${styles.inputField} ${
                          errors.category ? styles.errorInput : ""
                        }`}
                      >
                        <option value="">Select Category *</option>
                        <option value="professor">Professor</option>
                        <option value="supervisor">Supervisor</option>
                        <option value="colleague">Colleague</option>
                        <option value="other">Other</option>
                      </select>
                      {errors.category && (
                        <div className={styles.errorText}>
                          {errors.category}
                        </div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="text"
                        name="title"
                        placeholder="Job Title *"
                        value={formData.title}
                        onChange={handleChange}
                        className={`${styles.inputField} ${
                          errors.title ? styles.errorInput : ""
                        }`}
                      />
                      {errors.title && (
                        <div className={styles.errorText}>{errors.title}</div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="text"
                        name="organization"
                        placeholder="Organization *"
                        value={formData.organization}
                        onChange={handleChange}
                        className={`${styles.inputField} ${
                          errors.organization ? styles.errorInput : ""
                        }`}
                      />
                      {errors.organization && (
                        <div className={styles.errorText}>
                          {errors.organization}
                        </div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="text"
                        name="department"
                        placeholder="Department"
                        value={formData.department}
                        onChange={handleChange}
                        className={styles.inputField}
                      />
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="file"
                        name="resume"
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: "resume",
                              value: e.target.files[0],
                            },
                          })
                        }
                        className={styles.fileInput}
                        accept=".pdf,.doc,.docx"
                      />
                    </div>
                  </div>

                  {/* Right Column - Relationship Information */}
                  <div className={styles.formSection}>
                    <h3>Relationship Information</h3>

                    <div className={styles.formField}>
                      <select
                        name="frequency"
                        value={formData.frequency}
                        onChange={handleChange}
                        className={`${styles.inputField} ${
                          errors.frequency ? styles.errorInput : ""
                        }`}
                      >
                        <option value="">Contact Frequency *</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="quarterly">Quarterly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                      {errors.frequency && (
                        <div className={styles.errorText}>
                          {errors.frequency}
                        </div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="number"
                        name="years_of_known"
                        placeholder="Years Known *"
                        value={formData.years_of_known}
                        onChange={handleChange}
                        step="0.1"
                        min="0"
                        className={`${styles.inputField} ${
                          errors.years_of_known ? styles.errorInput : ""
                        }`}
                      />
                      {errors.years_of_known && (
                        <div className={styles.errorText}>
                          {errors.years_of_known}
                        </div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <input
                        type="text"
                        name="relationship"
                        placeholder="Relationship *"
                        value={formData.relationship}
                        onChange={handleChange}
                        className={`${styles.inputField} ${
                          errors.relationship ? styles.errorInput : ""
                        }`}
                      />
                      {errors.relationship && (
                        <div className={styles.errorText}>
                          {errors.relationship}
                        </div>
                      )}
                    </div>

                    <div className={styles.formField}>
                      <textarea
                        name="comments"
                        placeholder="Additional Comments"
                        value={formData.comments}
                        onChange={handleChange}
                        className={styles.textArea}
                        rows="4"
                      />
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "letters" && (
                <div className={styles.letterGenerationForm}>
                  <div className={styles.letterSectionsContainer}>
                    {renderAllSections()}

                    <div className={styles.endorsementSection}>
                      <h3>Endorsed Goal(s)</h3>
                      <div className={styles.helperText}>
                        Select your goals for endorsement
                      </div>
                      <div className={styles.goalsList}>
                        {ENDORSEMENT_GOALS.map((goal) => (
                          <div key={goal.id} className={styles.goalCheckbox}>
                            <input
                              type="checkbox"
                              id={`goal-${goal.id}`}
                              checked={selectedGoals.includes(goal.id)}
                              onChange={() => handleGoalSelection(goal.id)}
                            />
                            <label htmlFor={`goal-${goal.id}`}>
                              {goal.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className={styles.endorsementSection}>
                      <h3>Proposed Endeavors</h3>
                      <div className={styles.helperText}>
                        Select proposed endeavors for endorsement
                      </div>
                      <div className={styles.goalsList}>
                        {isLoadingEndeavors ? (
                          <div className={styles.loadingContainer}>
                            <div className={styles.loadingSpinner}></div>
                            <span>Loading proposed endeavors...</span>
                          </div>
                        ) : endorsedItems?.proposedEndeavor?.length > 0 ? (
                          endorsedItems.proposedEndeavor.map((endeavor) => (
                            <div
                              key={endeavor.id}
                              className={styles.goalCheckbox}
                            >
                              <input
                                type="checkbox"
                                id={`endeavor-${endeavor.id}`}
                                checked={endeavor.isChecked}
                                onChange={() =>
                                  handleItemSelection(
                                    "proposedEndeavor",
                                    endeavor.id
                                  )
                                }
                              />
                              <label htmlFor={`endeavor-${endeavor.id}`}>
                                <div className={styles.itemTitle}>
                                  {endeavor.title}
                                </div>
                                <div className={styles.itemDescription}>
                                  {endeavor.description}
                                </div>
                              </label>
                            </div>
                          ))
                        ) : (
                          <div className={styles.noItems}>
                            No selected endeavors available
                            {/* Add debug info */}
                            <div style={{ display: "none" }}>
                              {JSON.stringify(endorsedItems?.proposedEndeavor)}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.previewSection}>
                    <div className={styles.letterPreviewSection}>
                      <div className={styles.generateButtonContainer}>
                        <h3>Recommendation Letter Generator</h3>
                        <button
                          className={`${styles.generateButton} ${
                            isGenerating ? styles.generating : ""
                          }`}
                          onClick={handleGenerateLetter}
                          // disabled={isGenerateButtonDisabled()}
                        >
                          {isGenerating
                            ? "Generating Letter..."
                            : "Generate Now"}
                        </button>
                      </div>

                      <h3 className={styles.letterPreviewTitle}>
                        Preview your Recommendation Letter
                      </h3>
                      {renderPreviewSection()}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "final" && renderFinalLetter()}
              {renderButtons()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecommenderConfig;
