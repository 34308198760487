//Todo: after login, prevent users to go back to login page
import { redirect, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo.svg";
import hi from "../assets/images/login-image-hi.png";
import google from "../assets/images/login-social-icon-google-logo.svg";
import styles from "./Login.module.css";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../config.js";
import { GoogleLogin } from "@react-oauth/google";
import { authApi, getCsrfToken, handleAuthError } from "../services/api.js";
import { protectedApi } from "../services/api.js";
import { getApplicationId } from "../services/crudHelpers.js";
import { useAlert } from "./common/AlertContext.js";
function LogInPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [formError, setFormError] = useState("");
  const { showAlert } = useAlert();

  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedRememberMe = localStorage.getItem("rememberMe") === "true";

    if (storedUser && storedRememberMe) {
      navigate("/dashboard");
    }
    console.log("storedUser", storedUser);
    console.log("storedRememberMe", storedRememberMe);
    console.log("cookies", document.cookie);
    // Check remaining cookies after clearing
    const checkCookies = () => {
      const cookies = document.cookie;
      console.log("Remaining Cookies:", cookies);
      if (cookies.includes("csrftoken=")) {
        navigate("/dashboard");
        return;
      }
    };

    checkCookies();

    // clearAllStorage();
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const handleGoogleSuccess = async (credentialResponse) => {
  //   const { credential } = credentialResponse;

  //   try {
  //     const response = await authApi.post("/api/auth/google/", { credential });
  //     navigate("/personal-info");
  //   } catch (error) {
  //     console.error("Google Login Failed:", error);
  //     await showAlert("Failed to log in with Google. Please try again.");
  //     setFormError("Failed to log in with Google. Please try again.");
  //   }
  // };

  // const handleGoogleFailure = (error) => {
  //   console.error("Google Login Failed:", error);
  //   await showAlert("Failed to log in with Google. Please try again.");
  // };

  const renderErrorPopup = () => {
    if (!formError) return null;

    return <div className={styles.errorPopup}>{formError}</div>;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const loginResponse = await authApi.post("/api/auth/login/", {
        email,
        password,
      });

      // Only proceed if we have a valid response
      if (!loginResponse || !loginResponse.data) {
        throw new Error("Invalid response from server");
      }
      console.log(loginResponse.data);
      if (loginResponse.data.user) {
        localStorage.setItem("user", JSON.stringify(loginResponse.data.user));
        localStorage.setItem("rememberMe", rememberMe);

        if (!rememberMe) {
          sessionStorage.setItem(
            "user",
            JSON.stringify(loginResponse.data.user)
          );
          localStorage.removeItem("user");
          localStorage.removeItem("rememberMe");
        }
        const csrfToken = await getCsrfToken();
        localStorage.setItem("csrfToken", csrfToken);
        const applicationID = await getApplicationId();
        localStorage.setItem("applicationId", applicationID);
        if (applicationID) {
          navigate(`/dashboard`);
        } else {
          navigate("/personal-info");
        }
      }
    } catch (error) {
      console.error("Login error:", {
        status: error?.response?.status,
        data: error?.response?.data,
        message: error.message,
      });

      // Handle specific error cases
      if (error.response) {
        // Server responded with error
        if (error.response.status === 401) {
          showAlert("Invalid email or password");
        } else if (error.response.status === 403) {
          handleAuthError(error);
          showAlert("Access denied. Please try again.");
        } else {
          showAlert(
            error.response.data?.message || "Login failed. Please try again."
          );
        }
      } else if (error.request) {
        showAlert("No response from server. Please check your connection.");
      } else {
        showAlert(
          error.message || "Error making login request. Please try again."
        );
      }

      // Clear credentials on error
      if (error.response?.status === 401 || error.response?.status === 403) {
        localStorage.clear();
        sessionStorage.clear();

        // Clear cookies
        document.cookie.split(";").forEach((cookie) => {
          const [name] = cookie.split("=");
          const trimmedName = name.trim();
          document.cookie = `${trimmedName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
          document.cookie = `${trimmedName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${window.location.hostname}`;
        });
      }
    }
  };

  return (
    <div className={styles.login}>
      {renderErrorPopup()}
      <form onSubmit={handleSubmit}>
        <div className={styles.input}>
          <div className={styles.title}>Email address</div>
          <input
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.inputField}
          />
        </div>
        <div className={styles.input1}>
          <div className={styles.title}>Password</div>
          <div className={styles.textContainer}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className={styles.inputField1}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className={styles.iconButton}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
        <b className={styles.hiWelcome}>Hi, Welcome!</b>
        <div className={styles.rememberMe}>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            className={styles.checkbox}
          />
          <span className={styles.rememberMeText}>Remember me</span>
        </div>
        <img className={styles.image297Icon} alt="" src={hi} />

        <div className={styles.forgotPassword}>
          <Link to="/forgot-password" className={styles.forgotPasswordLink}>
            Forgot password?
          </Link>
        </div>
        <div className={styles.dontHaveAnContainer}>
          <span className={styles.dontHaveAnContainer1}>
            <span>Don't have an account?</span>
            <span className={styles.span}>{`   `}</span>
            <span className={styles.span}>
              <Link to="/register" className={styles.signUp1}>
                Sign up
              </Link>
            </span>
          </span>
        </div>
        {/* <div className={styles.orWithParent}>
          <div className={styles.orWith}>Or </div>
          <div className={styles.groupChild} />
          <div className={styles.groupItem} />
        </div> */}
        <button type="submit" className={styles.buttonPrimary}>
          Log in
        </button>
      </form>

      {/* <div className={styles.buttonWithCenteredIcon1}>
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
        />
      </div> */}
      <Link to="/">
        <img className={styles.navbarIcon} alt="logo" src={logo} />
      </Link>
    </div>
  );
}

export default LogInPage;
