import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../common/Sidebar.js";
import Header2 from "../common/Header2.js";
import styles from "./plans.module.css";
import Button from "../common/Button.js";
import { protectedApi } from "../../services/api.js";
import { FaShoppingCart } from "react-icons/fa";
import { useAlert } from "../common/AlertContext.js";

function Plans({ handleAddToCart }) {
  const navigate = useNavigate();
  const [cartId, setCartId] = useState(null);
  const [items, setItems] = useState([]);
  const { showAlert } = useAlert();

  useEffect(() => {
    const initializeData = async () => {
      try {
        const cartResponse = await protectedApi.post("/api/payments/cart/");
        setCartId(cartResponse.data.id);
        console.log("cartId", cartResponse.data.id);

        const itemsResponse = await protectedApi.get("/api/payments/items/");
        const itemsData = itemsResponse.data.results || [];
        console.log("Setting items:", itemsData);
        setItems(itemsData);
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    };

    initializeData();
  }, []);

  const handlePlanSelection = async (plan) => {
    console.log("Current items:", items);
    console.log("Looking for plan:", plan.name);

    if (!Array.isArray(items)) {
      console.error("Items is not an array:", items);
      return;
    }

    const item = items.find((item) => item.name === plan.name);
    if (!item) {
      console.error(`No matching item found for plan: ${plan.name}`);
      return;
    }

    try {
      // Add item to cart via API
      const response = await protectedApi.post(
        `/api/payments/cart/${cartId}/items/`,
        {
          item_id: item.id,
          quantity: plan.quantity || 1,
        }
      );
      console.log("response", response);
      if (response.status === 201) {
        // Update local cart state
        handleAddToCart({
          ...plan,
          id: item.id,
          quantity: plan.quantity || 1,
          price: plan.price * (plan.quantity || 1),
        });
        showAlert("Item added to cart successfully");
      } else {
        showAlert("Failed to add item to cart");
      }
    } catch (error) {
      showAlert(
        "Error adding item to cart, error: " + error.response.data.detail
      );
      console.error("Error adding item to cart:", error);
    }
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.headerSection}>
            <h2 className={styles.title}>Plans</h2>
            <Button
              className={styles.cartButton}
              onClick={() => navigate("/cart")}
            >
              <FaShoppingCart /> View Cart
            </Button>
          </div>

          <div className={styles.introSection}>
            <p className={styles.subtitle}>
              If it is your first time filing, we recommend you to choose from
              the below Packages.
            </p>
          </div>
          <div className={styles.plansContainer}>
            {/* Full Package */}
            <div className={styles.planCard}>
              <h3>Full Package</h3>
              <p className={styles.price}>$1049</p>
              <p className={styles.originalPrice}>$1349</p>
              <div className={styles.features}>
                <p>10 Propsed Endeavors</p>
                <p>5 Recommendation Letters</p>
                <p>1 Petition Letter</p>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() =>
                    handlePlanSelection({
                      name: "Full Package",
                      price: 1049,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </div>
            </div>

            {/* Full & RFE Package */}
            <div className={styles.planCard}>
              <h3>Full & RFE Package</h3>
              <p className={styles.price}>$1899</p>
              <p className={styles.originalPrice}>$2548</p>
              <div className={styles.features}>
                <p>10 Propsed Endeavor</p>
                <p>5 Recommendation Letters</p>
                <p>1 Petition Letter</p>
                <p>1 RFE Analysis</p>
                <p>1 RFE Notice Response</p>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() =>
                    handlePlanSelection({
                      name: "Full & RFE Package",
                      price: 1899,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </div>
            </div>

            {/* Elite Package */}
            <div className={styles.planCard}>
              <h3>Elite Package</h3>
              <p className={styles.price}>Custom Pricing</p>
              <div className={styles.features}>
                <p> Customer Service Support For Full Process</p>
                <p> Lawyer Assist To File Your Application </p>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() => navigate("/contact")}
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.rfeSection}>
            <p className={styles.subtitle}>
              If you already have a pending RFE, you can choose from the below
              Packages.
            </p>
          </div>

          <div className={styles.plansContainer}>
            {/* RFE Package */}
            <div className={styles.planCard}>
              <h3>RFE Package</h3>
              <p className={styles.price}>$1499</p>
              <p className={styles.originalPrice}>$1899</p>
              <div className={styles.features}>
                <p>No Proposed Endeavor</p>
                <p>1 RFE Analysis</p>
                <p>5 Recommendation Letters</p>
                <p>1 Response Letter </p>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() =>
                    handlePlanSelection({
                      name: "RFE Package",
                      price: 1499,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </div>
            </div>
            <div className={styles.planCard}>
              <h3>Elite Package</h3>
              <p className={styles.price}>Custom Pricing</p>
              <div className={styles.features}>
                <p>Custom Features</p>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() => navigate("/contact")}
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </div>

          {/* Add-Ons Section */}
          <h2 className={styles.sectionTitle}>Add-Ons</h2>
          <div className={styles.addOnsContainer}>
            <div className={styles.addOnCard}>
              <h3>Recommendation Letter</h3>
              <p className={styles.price}>$149</p>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() =>
                    handlePlanSelection({
                      name: "Recommendation Letter",
                      price: 149,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </div>
            </div>

            <div className={styles.addOnCard}>
              <h3>RFE Notice Analysis</h3>
              <p className={styles.price}>$599</p>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.planButton}
                  onClick={() =>
                    handlePlanSelection({
                      name: "RFE Notice Analysis",
                      price: 599,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plans;
