import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Header2.module.css";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/notification.svg";
import logo from "../../assets/images/logo.svg";

const Header2 = () => {
  const navigate = useNavigate();
  const userDataString = sessionStorage.getItem("user");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const userName = userData?.username || "Guest";

  return (
    <header className={styles.header}>
      <nav className={styles.navigation}>
        <div className={styles.navLinks}>
          <div
            className={styles.logoContainer}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img src={logo} alt="Logo" className={styles.logo} />
          </div>
          <div
            className={`${styles.navItem} ${styles.active}`}
            onClick={() => navigate("/dashboard")}
          >
            My Application
          </div>
          <div className={styles.navItem} onClick={() => navigate("/plans")}>
            Plans & Billings
          </div>
          <div
            className={styles.navItem}
            onClick={() => navigate("/resources")}
          >
            Resource Center
          </div>
        </div>
      </nav>

      <div className={styles.headerActions}>
        <button className={styles.iconButton}>
          <SearchIcon className={styles.icon} />
        </button>
        <button className={styles.iconButton}>
          <NotificationIcon className={styles.icon} />
        </button>
        <div
          className={styles.profileImage}
          onClick={() => navigate("/account-settings")}
          style={{ cursor: "pointer" }}
        >
          <span className={styles.username}>{userName}</span>
        </div>
      </div>
    </header>
  );
};

export default Header2;
