import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./rfe_analysis.module.css";
import Header2 from "../common/Header2";
import Sidebar from "../common/Sidebar";
import { checkUsageLimits } from "../../services/crudHelpers";
import { useAlert } from "../common/AlertContext";
import { ProgressBarContext } from "../dashboard/ProgressBar.js";

function RfeAnalysis() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { handleComplete } = useContext(ProgressBarContext);
  const [files, setFiles] = React.useState({
    petition: null,
    response: null,
  });

  const handleFileUpload = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [type]: file,
      }));
    }
  };

  const handleGenerate = async () => {
    const usageCheck = await checkUsageLimits("RFE Notice Analysis", showAlert);

    if (!usageCheck.success) {
      if (usageCheck.action === "redirect") {
        navigate("/plans");
      }
      showAlert(usageCheck.message);
      return;
    }

    handleComplete("rfe-analysis");

    // Continue with generation logic
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>RFE Analysis</h1>
            <div className={styles.rfeAnalysisCard}>
              <div className={styles.rfeAnalysisHeader}>
                <div className={styles.headerLeft}>
                  <span className={styles.aiIcon}>✨</span>
                  <span>Request for Evidence Analysis</span>
                </div>
              </div>
              <div className={styles.generatorContent}>
                <div className={styles.actionButtons}>
                  <div className={styles.uploadSection}>
                    <div className={styles.uploadBox}>
                      <label htmlFor="petitionLetter">
                        Upload Petition Letter
                      </label>
                      <input
                        type="file"
                        id="petitionLetter"
                        accept=".pdf,.doc,.docx"
                        className={styles.uploadInput}
                        onChange={(e) => handleFileUpload(e, "petition")}
                      />
                    </div>
                    <div className={styles.uploadBox}>
                      <label htmlFor="responseLetter">
                        Upload Response Letter
                      </label>
                      <input
                        type="file"
                        id="responseLetter"
                        accept=".pdf,.doc,.docx"
                        className={styles.uploadInput}
                        onChange={(e) => handleFileUpload(e, "response")}
                      />
                    </div>
                  </div>
                  <button
                    className={styles.generateButton}
                    onClick={handleGenerate}
                  >
                    Generate Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RfeAnalysis;
