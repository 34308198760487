import React, { useState } from "react";
import styles from "./VerificationPage.module.css"; // Add styles to make it visually appealing
import { authApi } from "../services/api";
import { useNavigate } from "react-router-dom";
import { useAlert } from "./common/AlertContext";
function VerificationPage() {
  const [isResending, setIsResending] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const handleResendClick = async (e) => {
    e.preventDefault();
    setIsResending(true);
    setMessage("");

    try {
      const email = localStorage.getItem("userEmail"); // Make sure to store this during registration
      if (!email) {
        showAlert("Please try sign up again.");
        navigate("/register");
        return;
      }

      const response = await authApi.post("/api/auth/resend-verification/", {
        email: email,
      });

      setMessage(
        response.data.message || "Verification email has been resent."
      );
    } catch (error) {
      setMessage(
        error.response?.data?.error ||
          "Failed to resend verification email. Please try again."
      );
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div className={styles.verificationContainer}>
      <div className={styles.iconContainer}>
        <i className="fas fa-envelope-open-text"></i>
      </div>
      <h1 className={styles.heading}>Check Your Email</h1>
      <div className={styles.divider}></div>
      <p className={styles.message}>
        Thank you for registering! A verification email has been sent to your
        provided email address. Please check your inbox (and spam folder) and
        click the verification link to activate your account.
      </p>
      <p className={styles.note}>
        If you did not receive the email, please wait a few minutes and check
        again, or{" "}
        <a
          href="#"
          className={styles.resendLink}
          onClick={handleResendClick}
          style={{ cursor: isResending ? "default" : "pointer" }}
        >
          {isResending ? "sending..." : "click here"}
        </a>{" "}
        to resend it.
      </p>
      {message && (
        <p className={`${styles.message} ${styles.feedbackMessage}`}>
          {message}
        </p>
      )}
    </div>
  );
}

export default VerificationPage;
