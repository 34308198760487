import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Account.module.css";
import Sidebar from "./common/Sidebar";
import Header from "./common/Header2";
import { authApi } from "../services/api.js";
import { useAlert } from "./common/AlertContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLoading } from "./common/LoadingContext";

function AccountSettingsPage() {
  const [userData, setUserData] = useState({
    email: "",
    username: "",
    lastLogin: "",
    isEmailVerified: false,
  });

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPasswords, setShowPasswords] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useAlert();
  const { showLoading, hideLoading } = useLoading();
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        showLoading("Fetching user data...");
        const user = JSON.parse(sessionStorage.getItem("user"));
        if (user) {
          setUserData({
            email: user.email,
            username: user.username,
            lastLogin: new Date().toLocaleDateString(),
            isEmailVerified: user.is_email_verified,
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        showAlert("Error fetching user data");
      } finally {
        hideLoading();
      }
    };

    fetchUserData();
  }, []);

  const togglePasswordVisibility = (field) => {
    setShowPasswords((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validatePassword = (password) => {
    return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,}$/.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    if (!validatePassword(passwordData.newPassword)) {
      setError(
        "Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, and one number"
      );
      setIsLoading(false);
      return;
    }

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError("Passwords do not match");
      setIsLoading(false);
      return;
    }

    try {
      const response = await authApi.post("/api/auth/change-password/", {
        old_password: passwordData.oldPassword,
        new_password: passwordData.newPassword,
      });

      showAlert("Password changed successfully!");
      setShowChangePassword(false);
      setPasswordData({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      setError(error.response?.data?.error || "Failed to change password");
    } finally {
      setIsLoading(false);
    }
  };

  const renderPasswordForm = () => (
    <form onSubmit={handleSubmit} className={styles.passwordForm}>
      <div className={styles.passwordField}>
        <label>Current Password</label>
        <div className={styles.passwordWrapper}>
          <input
            type={showPasswords.old ? "text" : "password"}
            name="oldPassword"
            value={passwordData.oldPassword}
            onChange={handlePasswordChange}
            required
          />
          <button
            type="button"
            onClick={() => togglePasswordVisibility("old")}
            className={styles.togglePassword}
          >
            {showPasswords.old ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
      </div>

      <div className={styles.passwordField}>
        <label>New Password</label>
        <div className={styles.passwordWrapper}>
          <input
            type={showPasswords.new ? "text" : "password"}
            name="newPassword"
            value={passwordData.newPassword}
            onChange={handlePasswordChange}
            required
          />
          <button
            type="button"
            onClick={() => togglePasswordVisibility("new")}
            className={styles.togglePassword}
          >
            {showPasswords.new ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
      </div>

      <div className={styles.passwordField}>
        <label>Confirm New Password</label>
        <div className={styles.passwordWrapper}>
          <input
            type={showPasswords.confirm ? "text" : "password"}
            name="confirmPassword"
            value={passwordData.confirmPassword}
            onChange={handlePasswordChange}
            required
          />
          <button
            type="button"
            onClick={() => togglePasswordVisibility("confirm")}
            className={styles.togglePassword}
          >
            {showPasswords.confirm ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
      </div>

      {error && <div className={styles.errorText}>{error}</div>}

      <div className={styles.formActions}>
        <button
          type="button"
          onClick={() => setShowChangePassword(false)}
          className={styles.cancelButton}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={styles.submitButton}
          disabled={isLoading}
        >
          {isLoading ? "Changing..." : "Change Password"}
        </button>
      </div>
    </form>
  );

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <Sidebar />
        <main className={styles.main}>
          <div className={styles.dashboardHeader}>
            <h1 className={styles.title}>Account Overview</h1>
          </div>

          <div className={styles.settingsContainer}>
            <section className={styles.settingsSection}>
              <h2 className={styles.sectionTitle}>Profile Information</h2>
              <div className={styles.settingsGrid}>
                <div className={styles.settingsItem}>
                  <label>Email</label>
                  <div className={styles.settingsValue}>{userData.email}</div>
                </div>
                <div className={styles.settingsItem}>
                  <label>Username</label>
                  <div className={styles.settingsValue}>
                    {userData.username}
                  </div>
                </div>
                {/* <div className={styles.settingsItem}>
                  <label>Email Status</label>
                  <div className={styles.settingsValue}>
                    {userData.isEmailVerified ? (
                      <span className={styles.verifiedBadge}>Verified</span>
                    ) : (
                      <span className={styles.unverifiedBadge}>
                        Not Verified
                      </span>
                    )}
                  </div>
                </div> */}
                <div className={styles.settingsItem}>
                  <label>Last Login</label>
                  <div className={styles.settingsValue}>
                    {userData.lastLogin}
                  </div>
                </div>
              </div>
            </section>

            <section className={styles.settingsSection}>
              <h2 className={styles.sectionTitle}>Security</h2>
              {!showChangePassword ? (
                <div className={styles.settingsActions}>
                  <button
                    onClick={() => setShowChangePassword(true)}
                    className={styles.actionButton}
                  >
                    Change Password
                    <span className={styles.actionArrow}>→</span>
                  </button>
                </div>
              ) : (
                renderPasswordForm()
              )}
            </section>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AccountSettingsPage;
