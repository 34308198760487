import React from "react";
import styles from "./TermsAndPrivacy.module.css";

function TermsAndPrivacy() {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <h1>Terms of Service and Privacy Policy</h1>
        <p>Last updated: 1/1/2025</p>

        <h2>1. Terms of Service</h2>

        <h3>1.1 Account Registration</h3>
        <p>By creating an account, you agree to:</p>
        <ul>
          <li>Provide accurate and complete information</li>
          <li>Maintain the security of your account credentials</li>
          <li>Accept responsibility for all activities under your account</li>
          <li>Notify us immediately of any unauthorized access</li>
        </ul>

        <h3>1.2 Acceptable Use</h3>
        <p>You agree not to:</p>
        <ul>
          <li>Use the service for any illegal purposes</li>
          <li>Share your account credentials with others</li>
          <li>Attempt to gain unauthorized access to our systems</li>
          <li>Upload malicious content or malware</li>
          <li>Interfere with other users' access to the service</li>
        </ul>

        <h3>1.3 Service Modifications</h3>
        <p>We reserve the right to:</p>
        <ul>
          <li>Modify or discontinue any part of our service</li>
          <li>Update these terms with reasonable notice</li>
          <li>Suspend or terminate accounts for violations</li>
        </ul>

        <h2>2. Privacy Policy</h2>

        <h3>2.1 Information We Collect</h3>
        <p>We collect and process:</p>
        <ul>
          <li>Account information (name, email, username)</li>
          <li>Usage data and analytics</li>
          <li>Device and browser information</li>
          <li>IP addresses and access logs</li>
          <li>Cookies and similar technologies</li>
        </ul>

        <h3>2.2 How We Use Your Information</h3>
        <p>Your information is used to:</p>
        <ul>
          <li>Provide and maintain our services</li>
          <li>Improve user experience</li>
          <li>Send service updates and communications</li>
          <li>Detect and prevent fraud or abuse</li>
          <li>Comply with legal obligations</li>
        </ul>

        <h3>2.3 Data Security</h3>
        <p>We implement security measures to:</p>
        <ul>
          <li>Protect your personal information</li>
          <li>Prevent unauthorized access</li>
          <li>Maintain data accuracy</li>
          <li>Ensure appropriate use of information</li>
        </ul>

        <h3>2.4 Data Retention</h3>
        <p>We retain your information:</p>
        <ul>
          <li>As long as your account is active</li>
          <li>As needed to provide our services</li>
          <li>As required by law</li>
          <li>Until you request deletion</li>
        </ul>

        <h3>2.5 Your Rights</h3>
        <p>You have the right to:</p>
        <ul>
          <li>Access your personal data</li>
          <li>Correct inaccurate information</li>
          <li>Request data deletion</li>
          <li>Object to data processing</li>
          <li>Export your data</li>
        </ul>

        <h2>3. Contact Us</h2>
        <p>For questions about these terms or your data:</p>
        <ul>
          <li>Email: [niwaction@gmail.com]</li>
        </ul>
      </div>
    </div>
  );
}

export default TermsAndPrivacy;
