import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./OrderConfirmation.module.css";
import Header2 from "../common/Header2";
import Sidebar from "../common/Sidebar";
import Button from "../common/Button";
import { protectedApi } from "../../services/api";

function OrderConfirmation() {
  const [orderStatus, setOrderStatus] = useState("processing");
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkOrderStatus = async () => {
      try {
        const orderId = localStorage.getItem("orderId");

        if (!orderId) {
          console.error("No orderId found");
          setIsLoading(false);
          return;
        }

        // Validate UUID format
        const uuidRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        if (!uuidRegex.test(orderId)) {
          console.error("Invalid UUID format:", orderId);
          setOrderStatus("Invalid order ID format");
          setIsLoading(false);
          return;
        }

        // Set up polling interval
        const pollInterval = setInterval(async () => {
          try {
            const response = await protectedApi.get(
              `/api/payments/order/${orderId}/status/`
            );

            console.log("Order status response:", response);
            const status = response.data.status;
            setOrderStatus(status);

            if (status === "DRAFT") {
              setOrderStatus("DRAFT");
              setIsLoading(false);
            }

            // Add unlock API call when status is COMPLETED
            if (status === "COMPLETED") {
              try {
                await protectedApi.post("/api/proposed-endeavor/unlock/all/");
              } catch (unlockError) {
                console.error(
                  "Error unlocking proposed endeavors:",
                  unlockError
                );
              }
              clearInterval(pollInterval);
              setIsLoading(false);
              setOrderStatus(status);
            } else if (status === "CANCELED") {
              clearInterval(pollInterval);
              setIsLoading(false);
              setOrderStatus(status);
            }
          } catch (error) {
            console.error("Error checking order status:", error);
            clearInterval(pollInterval);
            setOrderStatus(
              "There is an error when processing your order, please contact support"
            );
            setIsLoading(false);
          }
        }, 2000); // Poll every 2 seconds

        // Clean up interval after 5 minutes (300000ms) to prevent infinite polling
        setTimeout(() => {
          clearInterval(pollInterval);
          if (orderStatus === "DRAFT" || orderStatus === "OPEN") {
            setOrderStatus(
              "Payment processing timed out. Please contact support."
            );
            setIsLoading(false);
          }
        }, 300000);

        // Cleanup on component unmount
        return () => {
          clearInterval(pollInterval);
        };
      } catch (error) {
        console.error("Error details:", {
          message: error.message,
          response: error.response,
          status: error.response?.status,
          data: error.response?.data,
        });
        setOrderStatus(
          "There is an error when processing your order, please contact the team"
        );
        setIsLoading(false);
      }
    };

    checkOrderStatus();
  }, [location]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner}></div>
          <p className={styles.loadingText}>Processing your order...</p>
        </div>
      );
    }

    switch (orderStatus) {
      case "COMPLETED":
        return (
          <div className={styles.successContainer}>
            <div className={styles.checkmark}></div>
            <h2>Payment Successful!</h2>
            <p>Thank you for your purchase.</p>
            <Button
              className={styles.returnButton}
              onClick={() => navigate("/plans")}
            >
              Return to Plans
            </Button>
          </div>
        );

      case "FAILED":
        return (
          <div className={styles.errorContainer}>
            <div className={styles.errorIcon}></div>
            <h2>Payment Failed</h2>
            <p>There was an error processing your payment.</p>
            <Button
              className={styles.retryButton}
              onClick={() => navigate("/cart")}
            >
              Return to Cart
            </Button>
          </div>
        );

      case "CANCELED":
        return (
          <div className={styles.errorContainer}>
            <div className={styles.errorIcon}></div>
            <h2>Payment Cancelled</h2>
            <p>There was an error processing your payment. Please try again</p>
            <Button
              className={styles.retryButton}
              onClick={() => navigate("/cart")}
            >
              Return to Cart
            </Button>
          </div>
        );

      case "DRAFT":
        return (
          <div className={styles.processingContainer}>
            <div className={styles.loadingSpinner}></div>
            <h2>Payment Processing</h2>
            <p>Your payment is being processed. Please wait...</p>
            <p>Do not close or refresh this page.</p>
          </div>
        );

      default:
        return (
          <div className={styles.processingContainer}>
            <h2>Order Status: {orderStatus}</h2>
            <Button
              className={styles.returnButton}
              onClick={() => navigate("/plans")}
            >
              Return to Plans
            </Button>
          </div>
        );
    }
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>{renderContent()}</div>
      </div>
    </div>
  );
}

export default OrderConfirmation;
