import React, { useState, useRef, useEffect, useContext } from "react";
import styles from "./FuturePlan.module.css";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import config from "../../config.js";
import Sidebar from "../common/Sidebar.js";
import Button from "../common/Button.js";
import axios from "axios";
import Stepper from "../common/ProgressBar.js";
import { protectedApi, handleAuthError } from "../../services/api.js";
import Header2 from "../common/Header2.js";
import { usePreviousNavigation } from "../../services/handlePrevious";
import { useAlert } from "../common/AlertContext.js";
import { useLoading } from "../../components/common/LoadingContext"; // Add this import
import { ProgressBarContext } from "./ProgressBar.js";
import CustomDropdown from "../common/Dropdown.js";
// Add this helper function to convert file to base64
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]); // Remove data:application/pdf;base64, prefix
    reader.onerror = (error) => reject(error);
  });
};

// Define options as simple arrays of strings
const etaOptions = ["within 1 year", "1-3 years", "3-5 years", "5+ years"];
const statusOptions = ["Haven't Started Yet", "In Progress"];

function FuturePlanInfoPage() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { handleComplete } = useContext(ProgressBarContext);
  //   // 1. 把 useEffect 放在这里，组件的开始位置
  //   useEffect(() => {
  //     const applicationId = localStorage.getItem("applicationId");
  //     if (!applicationId) {
  //         showAlert("请先完成个人信息填写");
  //         navigate("/personal-info");
  //     }
  // }, [navigate]);

  const [futurePlans, setFuturePlans] = useState([
    {
      plan_title: "",
      area_of_focus: "",
      current_progress: "",
      plans: "",
      expected_outcomes: "",
      eta: "",
      status: "",
      impact: "",
      comments: "",
      supporting_doc: null,
    },
  ]);

  const [errors, setErrors] = useState([]);
  const [fileName, setFileName] = useState(""); // Add this state
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [openFuturePlans, setOpenFuturePlans] = useState([true]);
  const [deletedFuturePlans, setDeletedFuturePlans] = useState(new Set());
  const { showLoading, hideLoading } = useLoading();
  // useEffect(() => {
  //   const fetchExistingApplication = async () => {
  //     try {
  //       const response = await protectedApi.get("/api/application/");
  //       console.log("[GET] Application response:", response.data);

  //       if (
  //         response.data &&
  //         response.data.results &&
  //         response.data.results.length > 0
  //       ) {
  //         const currentApplication =
  //           response.data.results[response.data.results.length - 1];
  //         console.log("[GET] Current application data:", currentApplication);

  //         // Pre-fill form data
  //         setFuturePlans({
  //           planTitle: currentApplication.plan_title || "",
  //           areaOfFocus: currentApplication.area_of_focus || "",
  //           currentProgress: currentApplication.current_progress || "",
  //           plans: currentApplication.plans || "",
  //           expectedOutcomes: currentApplication.expected_outcomes || "",
  //           eta: currentApplication.eta || "",
  //           status: currentApplication.status || "",
  //           impact: currentApplication.impact || "",
  //           file: null,
  //           fileName: "",
  //         });

  //         // Set resume filename if exists
  //         if (currentApplication.file) {
  //           setFileName(currentApplication.file.split("/").pop());
  //         }

  //         // Store application ID
  //         localStorage.setItem("applicationId", currentApplication.id);
  //       }
  //     } catch (error) {
  //       console.error("[GET] Error fetching application:", error);
  //     }
  //   };

  //   fetchExistingApplication();
  // }, []);

  const handleChange = (e, index) => {
    if (!e) return;

    const { name, value, type, checked } = e.target || e;
    setFuturePlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[index] = {
        ...updatedPlans[index],
        [name]: type === "checkbox" ? checked : value,
      };
      return updatedPlans;
    });
  };

  const handleCancel = () => {
    navigate("/");
  };

  const validateForm = () => {
    const newErrors = futurePlans.map((futurePlan) => {
      const futurePlanErrors = {};

      // Required fields based on model (excluding nullable fields)
      const requiredFields = [
        "plan_title",
        "area_of_focus",
        "current_progress",
        "plans",
        "expected_outcomes",
        "eta",
        "status",
        "impact",
      ];

      requiredFields.forEach((field) => {
        const value = futurePlan[field];
        if (
          !value ||
          (typeof value === "string" && value.trim().length === 0)
        ) {
          // Convert field name to title case (first letter of each word uppercase)
          const fieldName = field
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          futurePlanErrors[field] = `${fieldName} is required`;
        }
      });

      return futurePlanErrors;
    });

    setErrors(newErrors);
    return newErrors.every(
      (futurePlanErrors) => Object.keys(futurePlanErrors).length === 0
    );
  };

  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();

    try {
      const applicationId = localStorage.getItem("applicationId");
      if (!applicationId) {
        showAlert(
          "No active application found. Please create a personal profile first."
        );
        navigate("/personal-info");
        return;
      }

      if (!validateForm()) {
        showAlert("Please fill in all required fields correctly");
        return;
      }

      // Filter out active future plans (not deleted)
      const activeFuturePlans = futurePlans.filter(
        (futurePlan) => !deletedFuturePlans.has(futurePlan.id)
      );

      // Prepare future plans for submission
      const futurePlansToSubmit = await Promise.all(
        activeFuturePlans.map(async (futurePlan) => {
          const fileIndex = futurePlans.indexOf(futurePlan);
          let fileData = null;

          if (files[fileIndex]) {
            try {
              const base64Data = await fileToBase64(files[fileIndex]);
              fileData = {
                filename: files[fileIndex].name,
                content_type: files[fileIndex].type,
                base64_content: base64Data,
              };
            } catch (error) {
              console.error(`Error converting file to base64:`, error);
            }
          }

          return {
            id: futurePlan.id, // Include if exists
            plan_title: futurePlan.plan_title,
            area_of_focus: futurePlan.area_of_focus,
            current_progress: futurePlan.current_progress,
            plans: futurePlan.plans,
            expected_outcomes: futurePlan.expected_outcomes,
            eta: futurePlan.eta,
            status: futurePlan.status,
            impact: futurePlan.impact,
            comments: futurePlan.comments || "",
            supporting_doc: fileData || futurePlan.supporting_doc,
            application: parseInt(applicationId),
          };
        })
      );

      // Separate future plans into updates and creates
      const futurePlansToUpdate = futurePlansToSubmit.filter((p) => p.id);
      const futurePlansToCreate = futurePlansToSubmit.filter((p) => !p.id);

      // Handle updates
      if (futurePlansToUpdate.length > 0) {
        const updateResponse = await protectedApi.patch(
          `/api/futureplan/`,
          futurePlansToUpdate,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );
        console.log("Update response:", updateResponse.data);
      }

      // Handle creates
      if (futurePlansToCreate.length > 0) {
        const createResponse = await protectedApi.post(
          "/api/futureplan/",
          futurePlansToCreate,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
              "X-CSRFToken":
                document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
            },
          }
        );
        console.log("Create response:", createResponse.data);
      }

      // Handle deletions
      if (deletedFuturePlans.size > 0) {
        const deletePromises = Array.from(deletedFuturePlans).map(
          (futurePlanId) =>
            protectedApi.delete(`/api/futureplan/${futurePlanId}/`, {
              headers: {
                "Content-Type": "application/json",
                "X-Application-ID": applicationId,
              },
            })
        );
        await Promise.all(deletePromises);
      }

      if (action === "continue") {
        handleComplete("future-plans");
        navigate("/proposed-endeavor");
      } else {
        handleComplete("future-plans");
        showAlert("Future plans saved successfully!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response?.data) {
        const errorMessage = Array.isArray(error.response.data)
          ? error.response.data.map((err) => err.error).join("\n")
          : error.response.data.error || "Unknown error";
        showAlert(`Error: ${errorMessage}`);
        if (handleAuthError(error, navigate)) return;
      } else {
        showAlert(error.message || "Error saving future plans");
      }
    }
  };

  // Helper function to handle individual future plan updates
  const handleFuturePlanUpdate = async (futurePlan, index) => {
    try {
      const applicationId = localStorage.getItem("applicationId");
      if (!applicationId) {
        throw new Error("No active application found");
      }

      const fileData = files[index]
        ? await prepareFileData(files[index])
        : null;
      const futurePlanData = prepareFuturePlanData(futurePlan, fileData);

      if (futurePlan.id) {
        await protectedApi.patch(
          `/api/futureplan/${futurePlan.id}/`,
          futurePlanData,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );
      } else {
        const response = await protectedApi.post(
          "/api/futureplan/",
          {
            ...futurePlanData,
            application: applicationId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );

        // Update local state with new future plan ID
        setFuturePlans((prevFuturePlans) => {
          const updatedFuturePlans = [...prevFuturePlans];
          updatedFuturePlans[index] = { ...futurePlan, id: response.data.id };
          return updatedFuturePlans;
        });
      }

      return true;
    } catch (error) {
      console.error("Error updating future plan:", error);
      throw error;
    }
  };

  // Helper functions for data preparation
  const prepareFileData = async (file) => {
    if (!file) return null;
    const base64Data = await fileToBase64(file);
    return {
      filename: file.name,
      content_type: file.type,
      base64_content: base64Data,
    };
  };

  const prepareFuturePlanData = (futurePlan, fileData) => ({
    plan_title: futurePlan.plan_title,
    area_of_focus: futurePlan.area_of_focus,
    current_progress: futurePlan.current_progress,
    plans: futurePlan.plans,
    expected_outcomes: futurePlan.expected_outcomes,
    eta: futurePlan.eta,
    status: futurePlan.status,
    impact: futurePlan.impact,
    comments: futurePlan.comments || "",
    supporting_doc: fileData || null,
  });

  const handleSave = (e) => handleFormSubmit(e, "save");
  const handleContinue = (e) => handleFormSubmit(e, "continue");

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const validFiles = newFiles.filter((file) => {
      if (file.type === "application/pdf") {
        return true;
      }
      showAlert(`${file.name} is not a PDF file`);
      return false;
    });

    if (validFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
      setFileNames((prevNames) => [
        ...prevNames,
        ...validFiles.map((f) => f.name),
      ]);
    }
  };

  const removeFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    setFileNames((prevNames) =>
      prevNames.filter((_, index) => index !== indexToRemove)
    );
  };

  const addNewFuturePlan = () => {
    setFuturePlans((prevFuturePlans) => [
      ...prevFuturePlans,
      {
        plan_title: "",
        area_of_focus: "",
        current_progress: "",
        plans: "",
        expected_outcomes: "",
        eta: "",
        status: "",
        impact: "",
        comments: "",
        supporting_doc: null,
      },
    ]);
    setOpenFuturePlans((prevFuturePlans) => [...prevFuturePlans, true]);
    setErrors((prevErrors) => [...prevErrors, {}]);
  };

  const toggleFuturePlans = (index) => {
    setOpenFuturePlans((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleDeleteFuturePlan = async (index) => {
    try {
      const futurePlanToDelete = futurePlans[index];

      // Don't allow deletion if it's the last future plan
      if (futurePlans.length <= 1) {
        showAlert("You must have at least one future plan.");
        return;
      }

      // Make DELETE request with the future plan ID
      if (futurePlanToDelete.id) {
        await protectedApi.delete(`/api/futureplan/${futurePlanToDelete.id}/`);
        setDeletedFuturePlans(
          (prev) => new Set([...prev, futurePlanToDelete.id])
        );
      }

      // Update local state
      const updatedFuturePlans = [...futurePlans];
      updatedFuturePlans.splice(index, 1);
      setFuturePlans(updatedFuturePlans);
    } catch (error) {
      console.error("Error deleting future plan:", error);
      showAlert("Failed to delete future plan. Please try again.");
    }
  };

  // Add this validation function
  const validateFuturePlanData = (futurePlan) => {
    const requiredFields = [
      "plan_title",
      "area_of_focus",
      "current_progress",
      "plans",
      "expected_outcomes",
      "eta",
      "status",
      "impact",
    ];

    const missingFields = requiredFields.filter(
      (field) => !futurePlan[field] || futurePlan[field].trim() === ""
    );

    if (missingFields.length > 0) {
      console.error("Missing required fields:", missingFields);
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchExistingApplication = async () => {
      try {
        showLoading("Fetching future plans...");
        const applicationId = localStorage.getItem("applicationId");
        if (!applicationId) {
          showAlert("No application found, please create one in Personal Info");
          return;
        }

        console.log(
          "[GET] Fetching future plans for application:",
          applicationId
        );
        const response = await protectedApi.get("/api/futureplan/", {
          headers: {
            "X-Application-ID": applicationId,
          },
        });
        console.log("[GET] Future plans response:", response.data);

        if (response.data?.results?.length > 0) {
          // Filter active future plans (not deleted)
          const activeFuturePlans = response.data.results.filter(
            (futurePlan) => !futurePlan.is_deleted
          );
          console.log("[GET] Active future plans:", activeFuturePlans);

          if (activeFuturePlans.length > 0) {
            // Map future plans to form structure
            const futurePlanData = activeFuturePlans.map((futurePlan) => ({
              id: futurePlan.id,
              plan_title: futurePlan.plan_title || "",
              area_of_focus: futurePlan.area_of_focus || "",
              current_progress: futurePlan.current_progress || "",
              plans: futurePlan.plans || "",
              expected_outcomes: futurePlan.expected_outcomes || "",
              eta: futurePlan.eta || "",
              status: futurePlan.status || "",
              impact: futurePlan.impact || "",
              comments: futurePlan.comments || "",
              supporting_doc: futurePlan.supporting_doc || null,
            }));

            console.log("[GET] Mapped future plan data:", futurePlanData);
            setFuturePlans(futurePlanData);
            setOpenFuturePlans(new Array(futurePlanData.length).fill(true));
            setErrors(new Array(futurePlanData.length).fill({}));

            // Handle files if they exist
            const existingFiles = futurePlanData
              .map((futurePlan) => futurePlan.supporting_doc)
              .filter(Boolean);

            console.log("[GET] Existing files:", existingFiles);
            setFileNames(existingFiles.map((file) => file.split("/").pop()));
          }
        } else {
          console.log("[GET] No existing future plans found");
        }
      } catch (error) {
        console.error("[GET] Error fetching future plans:", error);
        if (error.response) {
          console.error("[GET] Error response data:", error.response.data);
        }
        showAlert("Error fetching future plans");
      } finally {
        hideLoading();
      }
    };

    fetchExistingApplication();
  }, [navigate]);

  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.type !== "application/pdf") {
      showAlert("Please upload a PDF file");
      return;
    }

    try {
      // Update the futurePlan state with the new file
      setFuturePlans((prevPlans) => {
        const updatedPlans = [...prevPlans];
        updatedPlans[index] = {
          ...updatedPlans[index],
          supporting_doc: file,
        };
        return updatedPlans;
      });

      // Clear any existing errors for supporting_doc
      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (updatedErrors[index]) {
          delete updatedErrors[index].supporting_doc;
        }
        return updatedErrors;
      });
    } catch (error) {
      console.error("Error handling file upload:", error);
      showAlert("Error uploading file. Please try again.");
    }
  };

  const handleRemoveFile = (index) => {
    // Remove the file from the futurePlan
    setFuturePlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[index] = {
        ...updatedPlans[index],
        supporting_doc: null,
      };
      return updatedPlans;
    });
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.createProfile}>
          <div className={styles.wrapper}>
            <h1 className={styles.createYourProfile}>Create Your Profile</h1>
            <form onSubmit={handleContinue}>
              <div className={styles.formContainer}>
                {futurePlans.map((futurePlan, index) => (
                  <div key={index} className={styles.formSection}>
                    <div
                      className={styles.futurePlanHeader}
                      onClick={() => toggleFuturePlans(index)}
                    >
                      <h2 className={styles.futurePlanTitle}>
                        {futurePlan.futurePlanName ||
                          `Future Plan ${index + 1}`}
                      </h2>
                      <div className={styles.headerActions}>
                        <svg
                          className={styles.deleteIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteFuturePlan(index);
                          }}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <svg
                          className={`${styles.dropdownIcon} ${
                            openFuturePlans[index] ? styles.open : ""
                          }`}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M6 9l6 6 6-6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    <div
                      className={`${styles.futurePlanContent} ${
                        openFuturePlans[index] ? styles.open : styles.closed
                      }`}
                    >
                      {index === 0 && (
                        <div className={styles.sectionHeader}>
                          <Stepper
                            currentStep={7}
                            totalSteps={7}
                            progressWidth={28}
                          />
                        </div>
                      )}

                      {/* Original Input Fields */}
                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="plan_title"
                            placeholder="Plan Title"
                            value={futurePlan.plan_title}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.plan_title ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.plan_title && (
                            <span className={styles.errorMessage}>
                              {errors[index].plan_title}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="area_of_focus"
                            placeholder="Area of Focus"
                            value={futurePlan.area_of_focus}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.area_of_focus
                                ? styles.errorInput
                                : ""
                            }`}
                            required
                          />
                          {errors[index]?.area_of_focus && (
                            <span className={styles.errorMessage}>
                              {errors[index].area_of_focus}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.inputWrapper}>
                        <textarea
                          name="current_progress"
                          placeholder="Current Progress"
                          value={futurePlan.current_progress}
                          onChange={(e) => handleChange(e, index)}
                          className={`${styles.textArea} ${
                            errors[index]?.current_progress
                              ? styles.errorInput
                              : ""
                          }`}
                          required
                        />
                        {errors[index]?.current_progress && (
                          <span className={styles.errorMessage}>
                            {errors[index].current_progress}
                          </span>
                        )}
                      </div>

                      <div className={styles.inputWrapper}>
                        <textarea
                          name="plans"
                          placeholder="Plans"
                          value={futurePlan.plans}
                          onChange={(e) => handleChange(e, index)}
                          className={`${styles.textArea} ${
                            errors[index]?.plans ? styles.errorInput : ""
                          }`}
                          required
                        />
                        {errors[index]?.plans && (
                          <span className={styles.errorMessage}>
                            {errors[index].plans}
                          </span>
                        )}
                      </div>

                      <div className={styles.inputWrapper}>
                        <textarea
                          name="expected_outcomes"
                          placeholder="Expected Outcomes"
                          value={futurePlan.expected_outcomes}
                          onChange={(e) => handleChange(e, index)}
                          className={`${styles.textArea} ${
                            errors[index]?.expected_outcomes
                              ? styles.errorInput
                              : ""
                          }`}
                          required
                        />
                        {errors[index]?.expected_outcomes && (
                          <span className={styles.errorMessage}>
                            {errors[index].expected_outcomes}
                          </span>
                        )}
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <label className={styles.dropdownLabel}>
                            Expected Time of Achievement (ETA)
                          </label>
                          <CustomDropdown
                            name="eta"
                            value={futurePlan.eta}
                            onChange={(e) => handleChange(e, index)}
                            options={etaOptions}
                            placeholder="Select ETA"
                            error={errors[index]?.eta}
                            required
                          />
                        </div>

                        <div className={styles.inputWrapper}>
                          <label className={styles.dropdownLabel}>
                            Current Implementation Status
                          </label>
                          <CustomDropdown
                            name="status"
                            value={futurePlan.status}
                            onChange={(e) => handleChange(e, index)}
                            options={statusOptions}
                            placeholder="Select Status"
                            error={errors[index]?.status}
                            required
                          />
                        </div>
                      </div>

                      {/* Impact Textarea - Full Width */}
                      <div className={styles.inputWrapper}>
                        <textarea
                          name="impact"
                          placeholder="Impact"
                          value={futurePlan.impact}
                          onChange={(e) => handleChange(e, index)}
                          className={`${styles.textArea} ${
                            errors[index]?.impact ? styles.errorInput : ""
                          }`}
                          required
                        />
                        {errors[index]?.impact && (
                          <span className={styles.errorMessage}>
                            {errors[index].impact}
                          </span>
                        )}
                      </div>

                      <div className={styles.fileUploadContainer}>
                        <input
                          type="file"
                          accept=".pdf"
                          onChange={(e) => handleFileUpload(e, index)}
                          className={styles.fileInput}
                          id={`fileUpload-${index}`}
                          multiple
                        />
                        <label
                          htmlFor={`fileUpload-${index}`}
                          className={styles.fileInputLabel}
                        >
                          Upload Supporting Documents (PDF)
                        </label>

                        <div className={styles.uploadedFiles}>
                          {futurePlan.supporting_doc && (
                            <div className={styles.fileItem}>
                              <span>
                                {futurePlan.supporting_doc.name ||
                                  futurePlan.supporting_doc.split("/").pop()}
                              </span>
                              <button
                                type="button"
                                onClick={() => handleRemoveFile(index)}
                                className={styles.removeFile}
                                aria-label="Remove file"
                              >
                                ×
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Form Actions - Outside the projects loop */}
                <div className={styles.formActions}>
                  <button
                    type="button"
                    className={styles.secondaryButton}
                    onClick={addNewFuturePlan}
                  >
                    Add Another Plan
                  </button>
                  <div className={styles.navButtons}>
                    <Button
                      variant="secondary"
                      onClick={usePreviousNavigation()}
                    >
                      Previous
                    </Button>
                    <Button variant="secondary" onClick={handleSave}>
                      Save
                    </Button>
                    <Button variant="primary" onClick={handleContinue}>
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FuturePlanInfoPage;
